import { ImportUtils } from './../../utils/import-utils';
// import { Chart } from 'chart.js';
import { Component, ViewChild, OnInit, ElementRef, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { AgGridAngular } from '@ag-grid-community/angular';
import { map, debounceTime, filter, distinctUntilChanged, take } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { AddressService } from '@app/services/address.service';
import { ChartService } from '@app/services/chart.service';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { Module } from '@ag-grid-community/core';
import { StopsCustomDateModalComponent } from '@app/modals/stops-custom-date-modal/stops-custom-date-modal.component';
import { ModalService } from '@app/services/modal.service';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { CookieService } from 'ngx-cookie-service';
import { ImporterService } from '@app/services/importer.service';
import { ShipmentsHistoryMapComponent } from './shipments-history-map/shipments-history-map.component';
import { MilyService } from '@app/services/mily.service';
import { GenericService } from '@app/services/generic.service';
import { Router } from '@angular/router';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { GridUtils } from '@app/utils/grid-utils';
import { GenericUtils } from '@app/utils/generic-utils';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  ChartOptions
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

@Component({
  selector: 'app-shipments-history-grid',
  templateUrl: './shipments-history-grid.component.html',
  styleUrls: ['./../grids.scss', './shipments-history-grid.component.scss']
})
export class ShipmentsHistoryGridComponent implements OnInit {
  @ViewChild('stopPointsGrid', { static: false }) stopPointsGrid: AgGridAngular;
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild('filtersContainer', { static: false }) filtersContainer;
  @ViewChild('statsContainer', { static: false }) statsContainer;
  @ViewChild('gridContainer', { static: false }) gridContainer;
  @ViewChild('filterAllContainer', { static: false }) filterAllContainer;
  @ViewChild('filterInProgressContainer', { static: false }) filterInProgressContainer;
  @ViewChild('filterHistoryContainer', { static: false }) filterHistoryContainer;
  @ViewChild('filterInWarehouseContainer', { static: false }) filterInWarehouseContainer;
  @ViewChild('openChartButton', { static: false }) openChartButton;
  @ViewChild('openFiltersButton', { static: false }) openFiltersButton;
  @ViewChild('openMapButton', { static: false }) openMapButton;
  @ViewChild('openPrintButton', { static: false }) openPrintButton;
  @ViewChild('openXlsButton', { static: false }) openXlsButton;
  @ViewChild('printDropdown', { static: false }) printDropdown;
  @ViewChild('checkboxAll', { static: false }) checkboxAll;
  @ViewChild('mapContainer', { static: false }) mapContainer;
  @ViewChild(StopsCustomDateModalComponent, { static: false }) stopsCustomDateModalComponent: StopsCustomDateModalComponent;
  @ViewChild(ShipmentsHistoryMapComponent, { static: false }) stopsGridMapComponent: ShipmentsHistoryMapComponent;
  @ViewChild('fileInput', { read: ElementRef, static: false }) fileInput: ElementRef;

  public modules: Module[] = [
    ServerSideRowModelModule,
    // MenuModule,
    // ColumnsToolPanelModule,
  ];

  listen = [];
  searchTextChanged = new Subject<string>();
  searchString: String = '';
  userQuestionUpdate = new Subject<string>();
  selectAllCheckbox = false;
  gridApi;
  gridColumnApi;
  domLayout = 'autoHeight';
  shipmentsDataArray = [];
  rowData: any;
  rowModelType = 'serverSide';
  nameTitle;
  addressTitle;
  dateTitle;
  collaboratorTitle;
  volumeWeightTitle;
  payOnDeliveryTitle;
  statusTitle;
  arrivalTimeTitle;
  cancelOtherReasonMsg;
  cancelNotThereMSG;
  cancelWrongAddressMSG;
  cancelDidNotAcceptMSG;
  currency;

  fullDataShownForExport;
  bulkRequestsSize = 200;
  maxRequestsToMake = 5;

  stopPointsCount;
  deliveryStopPointsArray = [];
  pickupStopPointsArray = [];

  whenArray = ['today', 'last week', 'last month', 'custom'];
  selectedWhen;
  filterType = '';
  stopPointsArray;
  noVouchersAlert;

  // chart
  chartExpanded = true;
  chartDisabled = false;
  chart;
  chartData = {};
  charts = [];
  selectedChart;
  months = [];
  chartValues = [];
  dataLoading = false;
  pageSize = 10;
  currentChartPage = 0;
  firstPage = true;
  lastPage = false;

  columnDefs;
  excelStyles;
  noNameLabel = '';
  noNameConstant = '_NO_NAME';
  pagesCount = 0;
  cacheBlockSize = 50;

  selectedDate = '';
  stopsPeriods = [];
  selectedStopsPeriod;
  selectedDatesQuery = '';
  customDateString = '';
  // invoiceStatusTitle;
  // sentLabel: string;
  // approvedLabel: string;
  // viewedLabel: string;
  // paidLabel: string;
  // overdueLabel: string;
  // createdLabel: string;
  recentLabel: string;
  lastWeekLabel: string;
  lastMonthLabel: string;
  customLabel: string;
  barcodeTitle: string;
  telephoneTitle: string;
  serialNumberTitle: string;
  canceledLabel = '';
  completedLabel = '';
  alternativeBarcodeTitle;

  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  voucherStatusValue: any;
  recipientTitle: string;
  createdTitle: string;
  timeTitle: string;
  volumeTitle: string;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private viewProjectProblemService: ViewProjectProblemService,
    public gridsService: GridsService,
    private chartService: ChartService,
    private addressService: AddressService,
    private modalService: ModalService,
    private cookieService: CookieService,
    private importerService: ImporterService,
    private milyService: MilyService,
    private genericService: GenericService,
    private router: Router,
    public stopPointUtils: StopPointUtils,
    public gridUtils: GridUtils,
    public genericUtils: GenericUtils,
    public importUtils: ImportUtils
  ) {
    this.options = { concurrency: 1 };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;

    this.listen.push(this.modalService.updateStopPointsDateListen().subscribe((dates) => {
      if (!dates.end) {
        dates.end = dates.start;
      }
      this.selectedDatesQuery = 'startDate=' + dates.start.replaceAll("/", "-") + '&endDate=' + dates.end.replaceAll("/", "-") + '&timezone=' + moment.tz.guess();
      this.customDateString = dates.start + ' - ' + dates.end;
      this.updateGrid();
      this.selectedStopsPeriod = {
        label: this.customDateString,
        value: 'custom'
      };
    }));
    this.listen.push(this.genericService.listenStopPointsHistoryUpdate().subscribe((text) => {
      this.searchString = text;
      this.updateGrid();
    }));
    this.listen.push(this.milyService.uploadPointsDoneListen().subscribe((data) => {
      this.updateGrid();
    }));
    this.listen.push(this.modalService.updateStopPointsGridListen().subscribe((data) => {
      this.updateGrid();
    }));

    this.listen.push(this.genericService.listenSubmitPartner().subscribe(() => {
      this.updateGrid();
    }));

    // partner view services
    this.listen.push(this.genericService.listenOpenShipmentsHistoryFilters().subscribe(() => {
      this.openFilters();
    }));
    this.listen.push(this.genericService.listenOpenShipmentsHistoryChart().subscribe(() => {
      this.openChart();
    }));
  }

  updateGrid() {
    this.pagesCount = 0;
    // this.gridApi.purgeServerSideCache([]);
    this.gridApi.refreshServerSideStore({ purge: true });
  }

  // chart
  getDataForChart() {
    let data = [];
    // switch (this.selectedChart.value) {
    //   case 'most-visited':
    //     data = this.whenArray;
    //     break;
    // }
    return data;
  }

  displayDummyChartValues() {
    this.chartDisabled = true;

    this.months = [];
    for (let i = 1; i < 8; i++) {
      this.months.push(moment.utc().add(i, 'day').format('DD-MM'));
    }

    this.chartValues = [22, 17, 16, 16, 15, 9, 13];
  }

  getChartData() {
    this.dataLoading = true;
    let params = '?pageSize=' + this.pageSize;
    params += '&page=' + this.currentChartPage;
    params += '&date=' + moment().utc().format();
    // params += '&days=' + this.selectedWhen.value;
    if (this.currentChartPage === 0) {
      this.firstPage = true;
    } else {
      this.firstPage = false;
    }
    this.displayDummyChartValues();
    this.initChart();
  }

  initChart() {
    const ctx = <HTMLCanvasElement>document.getElementById('stop-point-chart');
    const data = this.getDataForChart();
    if (this.chart) { this.chart.destroy(); }
    const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptions));
    chartOptions.plugins.legend.display = true;
    if (ctx) {
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.months,
          datasets: [
            {
              label: this.completedLabel,
              data: this.chartValues,
              //fill: true,
              borderColor: '#00aeba',
              // borderColor: '#4fbd76',
              // backgroundColor: '#00aeba70',
              backgroundColor: '#00aeba70',
            },
          ]
        },
        options: chartOptions
      });
    }
  }

  // white bar button actions
  removeActiveButtonClass() {
    document.querySelector('.white-bar-right-button.active').classList.remove('active');
  }

  openChart() {
    if (this.router.url.split('/')[1] == 'collaboratorPartnerView') {
      this.removeActiveButtonClass();
      this.openChartButton.nativeElement.classList.add('active');
    }

    this.gridContainer.nativeElement.classList.remove('hidden');
    this.statsContainer.nativeElement.classList.remove('hidden');
    this.filtersContainer.nativeElement.classList.add('hidden');
    this.checkboxAll.nativeElement.classList.remove('hidden');

    // this.mapContainer.nativeElement.classList.add('hidden');
    this.mapContainer.nativeElement.classList.remove('visible');
  }

  openFilters() {
    if (this.router.url.split('/')[1] == 'collaboratorPartnerView') {
      this.removeActiveButtonClass();
      this.openFiltersButton.nativeElement.classList.add('active');
    }

    this.gridContainer.nativeElement.classList.remove('hidden');
    this.statsContainer.nativeElement.classList.add('hidden');
    this.filtersContainer.nativeElement.classList.remove('hidden');
    this.checkboxAll.nativeElement.classList.remove('hidden');

    // this.mapContainer.nativeElement.classList.add('hidden');
    this.mapContainer.nativeElement.classList.remove('visible');
  }

  openMapAction() {
    this.globals.comingSoonAlert();
    // this.removeActiveButtonClass();
    // this.openMapButton.nativeElement.classList.add('active');

    // this.gridContainer.nativeElement.classList.add('hidden');
    // this.statsContainer.nativeElement.classList.add('hidden');
    // this.filtersContainer.nativeElement.classList.add('hidden');
    // this.checkboxAll.nativeElement.classList.add('hidden');

    // this.mapContainer.nativeElement.classList.remove('hidden');
    this.mapContainer.nativeElement.classList.add('visible');
    // this.stopsGridMapComponent.initMap();

    // this.globals.comingSoonAlert();
  }

  // print dropdown + options
  dropdownToggle() {
    if (this.printDropdown.nativeElement.classList.contains('hidden')) {
      this.openPrintButton.nativeElement.classList.add('active');
      this.printDropdown.nativeElement.classList.remove('hidden');
    } else {
      this.openPrintButton.nativeElement.classList.remove('active');
      this.printDropdown.nativeElement.classList.add('hidden');
    }
  }

  printAll(forUps = false) {
    let stopPointsWithVouchers = [];

    // this.http.get('api/internal/v2/partner-stop-points?isPrinted=0&page=0').pipe(take(1)).subscribe(response => {
    this.http.get('api/v1/partner-stop-points?isPrinted=0&page=0').pipe(take(1)).subscribe(response => {
      let totalPages = response['itemsMeta'].pagesCount;
      stopPointsWithVouchers = stopPointsWithVouchers.concat(response['items']);

      if (totalPages) {
        let endRequestCounter = 1;

        if (totalPages > 1) {
          for (let page = 1; page < totalPages; page++) {
            // let url = 'api/internal/v2/partner-stop-points?isPrinted=0&page=' + page;
            let url = 'api/v1/partner-stop-points?isPrinted=0&page=' + page;
            this.http.get(url).pipe(take(1)).subscribe(response => {
              stopPointsWithVouchers = stopPointsWithVouchers.concat(response['items']);
              endRequestCounter++;

              if (endRequestCounter >= totalPages && stopPointsWithVouchers.length) {
                // opening a tab async causes browsers to block the popup
                // so we click at an invisible button and this triggers the window.open
                if (forUps) {
                  this.stopPointUtils.printImageVouchers(stopPointsWithVouchers, true);
                } else {
                  this.stopPointUtils.printVouchers(stopPointsWithVouchers);
                }
              }
            });
          }
        } else {
          // opening a tab async causes browsers to block the popup
          // so we click at an invisible button and this triggers the window.open
          if (forUps) {
            this.stopPointUtils.printImageVouchers(stopPointsWithVouchers, true);
          } else {
            this.stopPointUtils.printVouchers(stopPointsWithVouchers);
          }
        }
      }
    });

    this.dropdownToggle();
  }

  printSelected() {
    let selectedStopPointsWithVouchers = this.getSelectedWithVouchersOnly(this.getSelectedRows());

    if (selectedStopPointsWithVouchers.length) {
      this.stopPointUtils.printVouchers(selectedStopPointsWithVouchers);
    } else {
      alert(this.noVouchersAlert);
    }

    this.dropdownToggle();
  }

  getSelectedWithVouchersOnly(selectedRows) {
    let selectedWithVouchersArray = [];

    selectedRows.forEach(stopPoint => {
      this.stopPointsArray.forEach(item => {
        if (item.stopPoint.id == stopPoint.id) {
          if (item.stopPoint.voucher) {
            selectedWithVouchersArray.push(item);
          }
        }
      });
    });

    return selectedWithVouchersArray;
  }

  deselectGridCheckboxes() {
    this.gridApi.deselectAll();
  }

  // restore the grid settings for normal use
  removeExportSettingsFromGrid() {
    this.gridApi.paginationSetPageSize(this.cacheBlockSize);
    this.cacheBlockSize = this.cacheBlockSize;
    this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.cacheBlockSize);
    this.fullDataShownForExport = false;
    this.pagesCount = 0;
    this.initGrid();
  }

  // set another data source to change between page sizes to export all items
  setServerSideDatasource() {
    const self = this;
    const dataSource = {
      rowCount: null,
      getRows: function (rowsParams) {
        setTimeout(function () {
          self.gridApi.showLoadingOverlay();
          if (self.fullDataShownForExport) {
            self.loadAllPagesToExport(rowsParams);
          } else {
            self.loadSinglePage(rowsParams);
          }
        }, 500);
      }
    };
    self.gridApi.setServerSideDatasource(dataSource);
  }

  openXlsAction() {
    this.gridApi.paginationSetPageSize(this.stopPointsCount);
    this.cacheBlockSize = this.stopPointsCount;
    this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.stopPointsCount);
    this.fullDataShownForExport = true;
    this.setServerSideDatasource();
  }

  // request and pass all grid data for xls export
  loadAllPagesToExport(params) {
    const pageSize = this.bulkRequestsSize;
    if (this.stopPointsCount) {
      let pagesCount = Math.ceil(this.stopPointsCount / pageSize);
      if (pagesCount > this.maxRequestsToMake) { pagesCount = this.maxRequestsToMake; }
      const done = [];
      let stopPointsArray = [];
      for (let i = 0; i < pagesCount; i++) {
        done.push(false);
        this.pagesCount = i;
        this.getStopsGridData(pageSize).pipe(take(1)).subscribe(stopsResponse => {
          stopPointsArray = stopPointsArray.concat(stopsResponse['items']);
          done[i] = true;
          if (done.every(Boolean)) {
            this.stopPointsArray = stopPointsArray;
            this.displayDataToGrid(params, stopPointsArray, 1);
            this.exportToXls();
            this.removeExportSettingsFromGrid();
          }
        });
      }
    }
  }

  // export data to xls
  exportToXls() {
    const excelParams = {
      processCellCallback: formattingFunction,
      allColumns: true,
      fileName: this.genericUtils.removeDotsFromString('lastmily_export')
    };
    function formattingFunction(params) {
      if (params.value) {
        if (params.value.name || params.value.name === '') {
          return params.value.name;
        } else if (params.value.load || params.value.weight) {
          let label = '';
          if (params.value.load) {
            label += params.value.load + 'lt '
          }
          if (params.value.weight) {
            label += params.value.weight + 'kg '
          }
          return label;
        } else if (params.value.time) {
          return params.value.time + ' ' + params.value.date;
        } else if (params.value.payOnDeliveryAmount) {
          return params.value.payOnDeliveryAmount;
        } else if (params.value.date) {
          return params.value.date;
        } else if (params.value.label) {
          return params.value.label;
        } else {
          return params.value;
        }
      }
    }
    this.gridApi.exportDataAsExcel(excelParams);
  }

  // filter button actions
  removeActiveFilterClass() {
    document.querySelector('.filter-type-box.active').classList.remove('active');
  }

  filterAll() {
    this.removeActiveFilterClass();
    this.filterAllContainer.nativeElement.classList.add('active');

    this.filterType = '';
    this.updateGrid();
  }

  filterHistory() {
    this.removeActiveFilterClass();
    this.filterHistoryContainer.nativeElement.classList.add('active');

    this.filterType = 'history=1';
    this.updateGrid();
  }

  filterInProgress() {
    this.removeActiveFilterClass();
    this.filterInProgressContainer.nativeElement.classList.add('active');

    this.filterType = 'inProgress=1';
    this.updateGrid();
  }

  filterInWarehouse() {
    this.removeActiveFilterClass();
    this.filterInWarehouseContainer.nativeElement.classList.add('active');

    this.filterType = 'inWarehouse=1';
    this.updateGrid();
  }

  onUploadOutput(output: UploadOutput): void {
    const jwt = this.cookieService.get('jwt');
    switch (output.type) {
      case 'allAddedToQueue':
        // uncomment this if you want to auto upload files when added
        const event: UploadInput = {
          type: 'uploadAll',
          // url: '/api/v1/import-analyzer',
          url: '/api/v1/project/problems/' + 1 + '/import-analyzer',
          fieldName: 'companyOrders',
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + jwt,
            'language': this.globals.currentLang
          }
        };
        this.uploadInput.emit(event);
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.files.push(output.file);
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
          this.gridApi.showLoadingOverlay();
        }
        break;
      case 'removed':
        // remove file from array when removed
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        // handle import analyzer errors
        this.importUtils.handleAnalyzerErrors(output['file']['response']['errors']);

        // The file is uploaded
        this.gridApi.hideOverlay();
        if (this.files) {
          const file = this.files[this.files.length - 1];
          const data = file.response.items;
          if (data) {
            const fileHash = data.csvSheets[0].csvFile;
            const constantFieldsArray = [];
            this.importerService.openImporterMatcher(data);
            this.updateGrid();
          }
        }

        break;
    }
  }

  // custom renderer for time
  timeRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().twFirstStart && !params.getValue().twSecondStart) {
        columnObject += '<div class="double-cell standard-width">' + params.getValue().date + '</div>'
        columnObject += '<div class="double-cell standard-width">(' + `${params.getValue().twFirstStart}-${params.getValue().twFirstEnd}` + ')</div>'
      }
      else if (params.getValue().twFirstStart && params.getValue().twSecondStart) {
        columnObject += '<div class="double-cell standard-width">' + params.getValue().date + '</div>'
        columnObject += '<div class="double-cell standard-width">(' + `${params.getValue().twFirstStart}-${params.getValue().twFirstEnd}` + ' ' + `${params.getValue().twSecondStart}-${params.getValue().twSecondEnd}` + ')</div>'
      }
      else {
        columnObject += '<div class="single-cell standard-width">' + params.getValue().date + '</div>'
      }
    }

    return columnObject;
  }

  getStopsGridData(pageSize) {
    const searchQuery = `?searchQuery=${this.searchString}`;

    // let url = 'api/internal/v2/partner-stop-points' + searchQuery;
    let url = 'api/v1/partner-stop-points' + searchQuery;
    url += '&pageSize=' + pageSize;
    url += '&page=' + this.pagesCount;
    url += '&' + this.filterType;
    url += '&' + this.selectedDatesQuery;

    return this.http.get(url);
  }

  displayDataToGrid(params, dataArray, pagesCount) {
    if (dataArray.length) {
      let lastRow = -1;
      this.pagesCount++;
      if ((dataArray.length < this.cacheBlockSize) || this.pagesCount === (pagesCount)) {
        lastRow = dataArray.length + ((this.pagesCount - 1) * this.cacheBlockSize);
      }
      params.successCallback(this.setStopsGridData(dataArray), lastRow);

    } else {
      if (this.pagesCount) {
        const lastRow = (this.pagesCount - 1) * this.cacheBlockSize;
        params.successCallback(this.setStopsGridData(dataArray), lastRow);
      } else {
        const noData = { noDataText: 'No data' }
        params.successCallback([noData], 1);
      }
    }
  }

  // request and pass grid data page by page
  loadSinglePage(params) {
    this.getStopsGridData(this.cacheBlockSize).pipe(take(1)).subscribe(response => {
      this.stopPointsCount = response['itemsMeta'].totalCount;
      this.stopPointsArray = response['items'];
      // this.stopPointsArray = response['items']['stopPoints'];

      this.displayDataToGrid(params, this.stopPointsArray, response['itemsMeta']['pagesCount']);
      this.gridApi.hideOverlay();
    },
      (error) => {
        console.error(error);
        params.failCallback();
        this.gridApi.hideOverlay();
      }
    );
  }

  createServerSideDatasource() {
    const self = this;
    return {
      getRows: function (params) {
        self.gridApi.showLoadingOverlay();
        self.loadSinglePage(params);
      },
    };
  }

  setStopsGridData(shipments) {
    this.shipmentsDataArray = []; // empty shipments array first
    let gridObject = {};

    if (shipments.length) {
      // vouchers.forEach(voucher => {
      shipments.forEach(shipmentData => {
        // Date
        const shipment = shipmentData.stopPoint;
        let creationDateFormatted = '';
        if (shipment.creation_datetime) {
          creationDateFormatted = moment(shipment.issue_date, 'YYYY-MM-DD').format('DD/MM/YYYY');
        }

        let completeDatetimeMoment = null;
        if (
          shipment.fulfillment_status == this.globals.stopPointFulfillmentStatusConstants['COMPLETED'] &&
          shipment.solution
        ) {
          completeDatetimeMoment = moment(shipment.solution.atTime);
        }

        // Time (Time Windows)
        let timeWindowStart, timeWindowEnd;
        let timeWindowSecondStart, timeWindowSecondEnd;
        let timeWindowRange, timeWindowRangeMinutes;
        if (shipment.time_windows[0]) {
          timeWindowStart = moment(shipment.time_windows[0].start, 'HH:mm:ss').format('HH:mm');
          timeWindowRange = shipment.time_windows[0].time_window_range;
          timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
          timeWindowEnd = moment(timeWindowStart, 'HH:mm').add(timeWindowRangeMinutes, 'minutes').format('HH:mm');

          if (shipment.time_windows[1]) {
            timeWindowSecondStart = moment(shipment.time_windows[1].start, 'HH:mm:ss').format('HH:mm');
            timeWindowRange = shipment.time_windows[1].time_window_range;
            timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
            timeWindowSecondEnd = moment(timeWindowSecondStart, 'HH:mm').add(timeWindowRangeMinutes, 'minutes').format('HH:mm');
          }
        }

        // invoice status
        // let invoiceStatusClass, invoiceStatusLabel = '-';
        // if (shipment.voucher && shipment.voucher.invoice) {
        //   switch (shipment.voucher.invoice.status) {
        //     case this.globals.invoiceStatusConstants['CREATED']:
        //       invoiceStatusLabel = this.createdLabel;
        //       invoiceStatusClass = 'status-created status-fix-sizing';
        //       break;
        //     case this.globals.invoiceStatusConstants['APPROVED']:
        //       invoiceStatusLabel = this.approvedLabel;
        //       invoiceStatusClass = 'status-created status-fix-sizing';
        //       break;
        //     case this.globals.invoiceStatusConstants['VIEWED']:
        //       invoiceStatusLabel = this.viewedLabel;
        //       invoiceStatusClass = 'status-on-going status-fix-sizing';
        //       break;
        //     case this.globals.invoiceStatusConstants['PAID']:
        //       invoiceStatusLabel = this.paidLabel;
        //       invoiceStatusClass = 'status-completed status-fix-sizing';
        //       break;
        //     case this.globals.invoiceStatusConstants['OVERDUE']:
        //       invoiceStatusLabel = this.overdueLabel;
        //       invoiceStatusClass = 'status-canceled status-fix-sizing';
        //       break;
        //     case this.globals.invoiceStatusConstants['SENT']:
        //       invoiceStatusLabel = this.sentLabel;
        //       invoiceStatusClass = 'status-on-going status-fix-sizing';
        //       break;
        //     default:
        //       invoiceStatusLabel = 'CASE ERROR';
        //   }
        // }
        // if (!this.globals.vouchersEnabled) {
        //   invoiceStatusLabel = '-';
        //   invoiceStatusClass = '';
        // }

        // volume
        let volumeObj = this.stopPointUtils.getStopPointVolumeWeightColumnData(shipment);

        // name / no name
        let nameValue;
        shipment.contact_name == this.noNameConstant ? nameValue = this.noNameLabel : nameValue = shipment.contact_name;

        // fulfillment status
        let fulfillmentObj = this.gridUtils.getFulfillmentStatus(shipment);

        // if pickup & delivery
        if (shipment &&
          shipment.relatedStopPoint &&
          shipment.service_type == this.globals.stopPointServiceTypeConstants['DELIVERY']) {
          fulfillmentObj = this.gridUtils.showFulfillmentBasedOnDeliveryStatus(shipment, null, shipment.relatedStopPoint);
        }

        // barcode (always use the delivery's barcode/voucher hash)
        let barcodeValue;
        if (shipment.relatedStopPoint && shipment.service_type == this.globals.stopPointServiceTypeConstants['PICKUP']) {
          barcodeValue = this.stopPointUtils.getBarcodeOrVoucherHash(shipment.relatedStopPoint);
        } else {
          barcodeValue = this.stopPointUtils.getBarcodeOrVoucherHash(shipment);
        }

        let alternativeBarcodeValue;
        if (shipment.alternative_barcode) {
          alternativeBarcodeValue = shipment.alternative_barcode;
        } else if (shipment.relatedStopPoint?.alternative_barcode) {
          alternativeBarcodeValue = shipment.relatedStopPoint.alternative_barcode;
        }

        const stopPointPhone = shipment.telephone ?? '-'

        gridObject = {
          id: shipment.id,
          details: {
            name: nameValue,
            code: barcodeValue,
            phone: stopPointPhone,
          },
          address: this.addressService.getAddressLabel(shipment.address),
          barcode: barcodeValue ?? '-',
          alternativeBarcode: alternativeBarcodeValue ?? '-',
          serialNumber: shipment.serial_number ?? '-',
          telephone: stopPointPhone ?? '-',
          time: {
            date: completeDatetimeMoment ? completeDatetimeMoment.format('DD/MM @ HH:mm') : '-',
            twFirstStart: timeWindowStart,
            twFirstEnd: timeWindowEnd,
            twSecondStart: timeWindowSecondStart,
            twSecondEnd: timeWindowSecondEnd
          },
          creation_datetime: creationDateFormatted,
          volume: volumeObj,
          status: {
            colorClass: fulfillmentObj.colorClass,
            label: fulfillmentObj.statusValue
          },
          payOnDelivery: this.gridUtils.getPayAmountStatus(shipment),
          // invoice_status: {
          //   colorClass: invoiceStatusClass,
          //   label: invoiceStatusLabel
          // },
          objData: shipment
        };
        this.shipmentsDataArray.push(gridObject);
      });
    } else {
      const noData = { noDataText: 'No data' }
      this.shipmentsDataArray.push(noData);
    }

    return this.shipmentsDataArray;
  }

  // checkboxes
  getSelectedRows() {
    return this.gridApi.getSelectedRows();
  }

  selectAll() {
    if (!this.selectAllCheckbox) {
      this.gridApi.forEachNode((row, index) => {
        this.gridApi.getRowNode(row.id).selectThisNode(true);
      });
    } else {
      this.gridApi.forEachNode((row, index) => {
        this.gridApi.getRowNode(row.id).selectThisNode(false);
      });
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  initGrid() {
    let intervalId = setInterval(() => {
      if (this.gridApi) {
        this.gridApi.setServerSideDatasource(this.createServerSideDatasource());
        clearInterval(intervalId);
      }
    }, 100);
  }

  rowClicked(event) {
    if (!event.data.noDataText) {
      this.viewProjectProblemService.openStopModal(event.data.id, event.data.projectProblemId);
    }
  }

  search($event) {
    this.searchTextChanged.next($event);
  }

  uploadStops() {
    // this.globals.comingSoonAlert();
    document.getElementById('csv-uploader').click();
  }

  comingSoon() {
    this.globals.comingSoonAlert();
  }

  stopsPeriodChange() {
    let startDate = '', endDate = '', timezone = '';
    timezone = moment.tz.guess()
    if (this.selectedStopsPeriod.value === 'custom') {
      this.stopsCustomDateModalComponent.openModal();
    } else {
      startDate = moment().subtract(this.selectedStopsPeriod.value, 'days').format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
      this.selectedDatesQuery = 'startDate=' + startDate + '&endDate=' + endDate + '&timezone=' + timezone;
      this.updateGrid();
    }
  }

  onFirstDataRendered(params) { }

  // custom cell renderer for name, code & phone
  nameCodePhoneRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().name) {
        columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
        columnObject += '<div class="double-cell standard-width"><i class="fas fa-phone-alt grey-letters"></i> ' + params.getValue().phone + ' <i class="fas fa-qrcode grey-letters"></i> ' + params.getValue().code + '</div>';
      }
    }
    return columnObject;
  }

  // custom renderer for invoice status
  // invoiceStatusRenderer(params) {
  //   let columnObject = '';
  //   if (params.getValue()) {
  //     columnObject += '<div class="voucher-status-container">' + '<div class="single-cell standard-width ' + params.getValue().colorClass + '">' + params.getValue().label + '</div>' + '</div>';
  //   }
  //   return columnObject;
  // }

  collaboratorRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      if (params.getValue().id) {
        columnObject += '<div class="single-cell"><a href="../customerCollaborators/collaboratorView/' + params.getValue().id + '">' + params.getValue().name + '</a></div>';
      } else {
        columnObject += '<div class="single-cell">' + params.getValue().name + '</div>';
      }
    }

    return columnObject;
  }

  // custom renderer for status
  statusRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      columnObject += '<div class="voucher-status-container">' + '<div class="single-cell standard-width ' + params.getValue().colorClass + '">' + params.getValue().label + '</div>' + '</div>';
    }
    return columnObject;
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.RECIPIENT').subscribe((res: string) => { this.recipientTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.CREATION_DATE').subscribe((res: string) => { this.createdTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.VOLUME').subscribe((res: string) => { this.volumeTitle = res; }));
    this.listen.push(this.translate.get('PARTNER_SHIPMENTS.TIME').subscribe((res: string) => { this.timeTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.ADDRESS').subscribe((res: string) => { this.addressTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.DATE_CREATED').subscribe((res: string) => { this.dateTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.COLLABORATOR').subscribe((res: string) => { this.collaboratorTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.STATUS').subscribe((res: string) => { this.statusTitle = res; }));
    // this.listen.push(this.translate.get('STOP_POINT.INVOICE_STATUS').subscribe((res: string) => { this.invoiceStatusTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.ARRIVAL_TIME').subscribe((res: string) => { this.arrivalTimeTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.STOP_POINTS_NO_VOUCHERS').subscribe((res: string) => { this.noVouchersAlert = res; }));
    this.listen.push(this.translate.get('STOP_POINT.VOLUME_WEIGHT').subscribe((res: string) => { this.volumeWeightTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.PAY_ON_DELIVERY').subscribe((res: string) => { this.payOnDeliveryTitle = res; }));
    // this.listen.push(this.translate.get('CUSTOMER_INVOICES.SENT').subscribe((res: string) => { this.sentLabel = res; }));
    // this.listen.push(this.translate.get('CUSTOMER_INVOICES.APPROVED').subscribe((res: string) => { this.approvedLabel = res; }));
    // this.listen.push(this.translate.get('CUSTOMER_INVOICES.VIEWED').subscribe((res: string) => { this.viewedLabel = res; }));
    // this.listen.push(this.translate.get('CUSTOMER_INVOICES.PAID').subscribe((res: string) => { this.paidLabel = res; }));
    // this.listen.push(this.translate.get('CUSTOMER_INVOICES.OVERDUE').subscribe((res: string) => { this.overdueLabel = res; }));
    // this.listen.push(this.translate.get('CUSTOMER_INVOICES.CREATED').subscribe((res: string) => { this.createdLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
    this.listen.push(this.translate.get('GENERIC.RECENT').subscribe((res: string) => { this.recentLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.LAST_WEEK').subscribe((res: string) => { this.lastWeekLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.LAST_MONTH').subscribe((res: string) => { this.lastMonthLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.CUSTOM').subscribe((res: string) => { this.customLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.BARCODE').subscribe((res: string) => { this.barcodeTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.PHONE').subscribe((res: string) => { this.telephoneTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.SERIAL').subscribe((res: string) => { this.serialNumberTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.STATUS_COMPLETED').subscribe((res: string) => { this.completedLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.STATUS_CANCELED').subscribe((res: string) => { this.canceledLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.ALTERNATIVE_BARCODE').subscribe((res: string) => { this.alternativeBarcodeTitle = res; }));
    this.getChartData();

    this.excelStyles = [
      {
        id: 'stringType',
        dataType: 'String',
      },
    ];

    // Grid Columns are different based on vouchersEnabled
    this.columnDefs = [
      {
        headerName: this.recipientTitle,
        field: 'details',
        checkboxSelection: true,
        cellRenderer: this.nameCodePhoneRenderer,
        width: this.gridsService.widthCalculator(17.5)
      },
      {
        headerName: this.createdTitle,
        field: 'creation_datetime',
        width: this.gridsService.widthCalculator(7.5)
      },
      {
        headerName: this.addressTitle,
        field: 'address',
        cellRenderer: this.gridsService.addressRenderer,
        width: this.gridsService.widthCalculator(15)
      },
      {
        headerName: this.telephoneTitle,
        field: 'telephone',
        hide: true,
        cellClass: 'stringType'
      },
      {
        headerName: this.barcodeTitle,
        field: 'barcode',
        hide: true,
      },
      {
        headerName: this.alternativeBarcodeTitle,
        field: 'alternativeBarcode',
        hide: true,
      },
      {
        headerName: this.serialNumberTitle,
        field: 'serialNumber',
        hide: true,
      },
      {
        headerName: this.timeTitle,
        field: 'time',
        cellRenderer: this.timeRenderer,
        width: this.gridsService.widthCalculator(15)
      },
      {
        headerName: this.volumeWeightTitle,
        field: 'volume',
        cellRenderer: this.gridsService.volumeWeightCountRenderer,
        width: this.gridsService.widthCalculator(15)
      },
      {
        headerName: this.payOnDeliveryTitle,
        field: 'payOnDelivery',
        cellRenderer: this.gridsService.payOnDeliveryRenderer.bind(this),
        width: this.gridsService.widthCalculator(15)
      },
      // {
      //   headerName: this.invoiceStatusTitle,
      //   field: 'invoice_status',
      //   cellRenderer: this.invoiceStatusRenderer,
      //   width: this.gridsService.widthCalculator(15)
      // },
      {
        headerName: this.statusTitle,
        field: 'status',
        cellRenderer: this.statusRenderer,
        width: this.gridsService.widthCalculator(15)
      },
    ];
    this.stopsPeriods = [
      {
        label: this.recentLabel,
        value: 1
      },
      {
        label: this.lastWeekLabel,
        value: 6
      },
      {
        label: this.lastMonthLabel,
        value: 29
      },
      {
        label: this.customLabel,
        value: 'custom'
      },
    ];
    this.selectedStopsPeriod = {
      label: this.recentLabel,
      value: 1
    };
  }

  ngOnInit() {
    const timezone = moment.tz.guess();
    const startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    this.selectedDatesQuery = 'startDate=' + startDate + '&endDate=' + endDate + '&timezone=' + timezone;
    this.initGrid();

    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe((text: string) => {
        this.updateGrid();
      });
  }

  ngAfterViewInit() {
    this.fileInput.nativeElement.onclick = (event) => { event.target.value = null; };
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }
}
