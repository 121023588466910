import countries from 'i18n-iso-countries';
import enCountriesJson from "i18n-iso-countries/langs/en.json";
import elCountriesJson from "i18n-iso-countries/langs/el.json";
import deCountriesJson from "i18n-iso-countries/langs/de.json";
import roCountriesJson from "i18n-iso-countries/langs/ro.json";
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '@app/services/modal.service';
import { Globals } from '@app/services/globals';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { Module } from '@ag-grid-community/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
// import { Chart } from 'chart.js';
import * as moment from 'moment';
import { ChartService } from '@app/services/chart.service';
import { GridsService } from '@app/services/grids.service';
import { AddressService } from '@app/services/address.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { GridUtils } from '@app/utils/grid-utils';
import { PaymentStatusSelectEditorComponent } from '@app/grids/grid-editors/payment-status-select-editor/payment-status-select-editor.component';
import { DataService } from '@app/services/data.service';
import { GenericUtils } from '@app/utils/generic-utils';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    ChartOptions
} from 'chart.js';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);

@Component({
    selector: 'app-collaborator-vouchers-grid',
    templateUrl: './collaborator-vouchers-grid.component.html',
    styleUrls: ['./../grids.scss', './collaborator-vouchers-grid.component.scss']
})
export class CollaboratorVouchersGridComponent implements OnInit, OnDestroy {
    @ViewChild('statsContainer', { static: false }) statsContainer;
    @ViewChild('gridContainer', { static: false }) gridContainer;
    @ViewChild('filtersContainer', { static: false }) filtersContainer;
    @ViewChild('filterAllContainer', { static: false }) filterAllContainer;
    @ViewChild('filterHistoryContainer', { static: false }) filterHistoryContainer;
    @ViewChild('filterInProgressContainer', { static: false }) filterInProgressContainer;
    @ViewChild('filterPayOnDeliveryContainer', { static: false }) filterPayOnDeliveryContainer;
    // @ViewChild('filterToBeInvoicedContainer', { static: false }) filterToBeInvoicedContainer;

    listen = [];

    public modules: Module[] = [
        ServerSideRowModelModule,
        // MenuModule,
        // ColumnsToolPanelModule,
    ];

    collaboratorId;

    showManualInvoicingButton;
    isManualInvoicingActive = false;
    selectAllCheckbox = false;
    selectedStopPointsCount = 0;
    selectedStopPointsAmount = 0;
    selectedDatesQuery = '';

    columnDefs;
    rowModelType = 'serverSide';
    cacheBlockSize = 50;
    defaultPaginationSize = 100;
    gridApi;
    gridColumnApi;
    rowData = [];
    isEditingActive = false;

    months = [];
    vouchersNumberTotal = [];
    vouchersNumberComplete = [];
    vouchersNumberCancelled = [];

    pagesCount = 0;
    customerVouchersDataArray = [];
    relatedStopPointsArray = [];
    allStopPointsObject = {};
    chargeCategories = [];
    basicVoucherServices = [];
    projectProblemsArray = [];

    vouchersCount = 0;
    searchTextChanged = new Subject<string>();
    searchString: String = '';
    clicked;
    nameCodeTitle;
    addressTitle;
    completeTitle;
    createdTitle;
    timeTitle;
    completeTimeTitle;
    completeDateTitle
    volumeTitle;
    shipmentStatusTitle;
    voucherStatusValue: string;
    payOnDeliveryTitle;
    payOnDeliveryStatusTitle;
    paymentAmountTitle;
    // paymentStatusTitle;
    paymentStatusAccountingProviderTitle;
    payOnDeliveryStatusAccountingProviderTitle;
    currency;
    receiverTitle;
    systemicVoucherTrackingNumberTitle;
    payAmountUnpaidLabel;
    payAmountReceivedLabel;
    payAmountPaidLabel;
    payAmountNoneLabel;
    showSortDropdown = false;
    selectedOrder = '';
    canceledLabel = '';
    completedLabel = '';
    streetTitle;
    cityTitle;
    postalCodeTitle;
    countryTitle;
    senderPostalCodeTitle;
    senderStreetTitle;
    senderCityTitle;
    senderCountryTitle;
    dateTitle;
    packagesCountTitle;
    fuelSurchargeTitle;
    taxTitle;
    totalChargeTitle;
    fareChargeTitle;
    telephoneTitle;
    paidByTitle;
    noteTitle;
    consignorLabel;
    receiverLabel;
    senderLabel;
    servicesTitle;
    servicesChargeTitle;
    volumeWeightTitle;
    loadTitle;
    weightTitle;
    litersUnit;
    kilosUnit;

    voucherBoundToInvoiceWarningMessage;
    isFilterChangeAllowed = false;

    chart;
    chartData = {};
    customerNames = [];
    customerShortNames = [];
    mostVisited = [];

    dataLoading = false;
    pageSize = 10;
    currentChartPage = 0;
    firstPage = true;
    lastPage = false;
    charts = [];
    selectedChart;
    periods = [];
    selectedPeriod;

    chartExpanded = true;
    chartDisabled = false;

    noNameLabel = '';
    returnLabel = '';
    noNameConstant = '_NO_NAME';
    returnConstant = '_RETURN';

    filterType = '';

    fullDataShownForExport = false;
    bulkRequestsSize = 2000;
    maxRequestsToMake = 5;

    extraFiltersOptions = [];
    selectedExtraFilters = [];
    extraFilters = '';

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        private modalService: ModalService,
        public globals: Globals,
        private modalGridService: ModalGridService,
        private chartService: ChartService,
        public gridsService: GridsService,
        private addressService: AddressService,
        private viewProjectProblemService: ViewProjectProblemService,
        public stopPointUtils: StopPointUtils,
        public gridUtils: GridUtils,
        public genericUtils: GenericUtils,
        public dataService: DataService
    ) {
        countries.registerLocale(enCountriesJson);
        countries.registerLocale(elCountriesJson);
        countries.registerLocale(deCountriesJson);
        countries.registerLocale(roCountriesJson);

        this.listen.push(this.modalGridService.listenUpdateCustomerGrid().subscribe((response) => {
            this.updateGrid();
        }));
        this.listen.push(this.modalGridService.listenUpdateVouchersGrid().subscribe(() => {
            this.updateGrid();
        }));
    }

    // update grid
    updateGrid() {
        this.customerVouchersDataArray = [];
        this.relatedStopPointsArray = [];
        this.pagesCount = 0;
        // this.gridApi.purgeServerSideCache([]);
        this.gridApi.refreshServerSideStore({ purge: true });
        const gridContainer = document.querySelector('#vouchers-grid-container .ag-body-viewport');
        gridContainer.scrollTo(0, 0);
    }

    cellValueChanged(event) {
        const stopPointsToUpdate = this.gridUtils.getStopPointsDataToSavePayOnDeliveryStatus(event, []);

        if (stopPointsToUpdate.length) {
            this.http.put('api/v1/stop-points', { stopPoints: stopPointsToUpdate }).pipe(take(1)).subscribe(response => {
                this.updateGrid();
            });
        }
    }

    newButtonClicked() {
        this.modalService.openCustomerModal(null);
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    getDataForChart() {
        let data = [];
        switch (this.selectedChart.value) {
            case 'most-visited':
                data = this.mostVisited;
                break;
        }
        return data;
    }

    openChart() {
        this.chartExpanded = true;
        this.statsContainer.nativeElement.classList.add('expanded');
        this.gridContainer.nativeElement.classList.add('reduced');
    }

    closeChart() {
        this.chartExpanded = false;
        this.statsContainer.nativeElement.classList.remove('expanded');
        this.gridContainer.nativeElement.classList.remove('reduced');
    }

    closeFilters() {
        this.filtersContainer.nativeElement.classList.add('hidden');
        this.showManualInvoicingButton = false;
    }

    openFilters() {
        this.filtersContainer.nativeElement.classList.remove('hidden');
        this.showManualInvoicingButton = true;
    }

    emptyChartArrays() {
        this.customerNames = [];
        this.customerShortNames = [];
        this.mostVisited = [];
    }

    getFirstWord(str) {
        return str.split(' ')[0];
    }

    loadNextChartPage() {
        if (!this.lastPage && !this.dataLoading) {
            this.dataLoading = true;
            this.currentChartPage++;
            this.emptyChartArrays();
            this.getChartData();
        }
    }

    loadPreviousChartPage() {
        if (!this.firstPage && !this.dataLoading) {
            this.dataLoading = true;
            this.currentChartPage--;
            this.emptyChartArrays();
            this.getChartData();
        }
    }

    displayDummyChartValues() {
        this.chartDisabled = true;

        this.months = [];
        for (let i = 1; i < 8; i++) {
            this.months.push(moment.utc().add(i, 'day').format('DD-MM'));
        }

        this.vouchersNumberTotal = [22, 17, 16, 16, 15, 9, 13];
        this.vouchersNumberComplete = [17, 15, 12, 14, 14, 8, 9];

        for (let i = 1; i < 8; i++) {
            this.vouchersNumberCancelled.push(this.vouchersNumberTotal[i] - this.vouchersNumberComplete[i]);
        }
    }

    // manual invoicing actions
    toggleManualInvoicing(event) {
        event.stopPropagation();
        this.isManualInvoicingActive ? this.isManualInvoicingActive = false : this.isManualInvoicingActive = true;
        this.toggleCheckBoxes();
    }

    toggleCheckBoxes() {
        // if manual invoicing is enabled, show checkboxes else hide them (checkboxes are toggled via checkboxRenderer)
        if (!this.isManualInvoicingActive) {
            this.clearSelectedVouchers();
        }

        // this is required to update the renderer
        this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(this.columnDefs);
    }

    invoicingAction() {
        let selectedVoucherIds = [];
        // this.gridApi.getSelectedNodes().forEach(stopPoint => {
        for (let stopPoint of this.gridApi.getSelectedNodes()) {
            // place inside vouchers array if voucher exists in sp
            if (stopPoint.data.objData.voucher) {
                selectedVoucherIds.push(stopPoint.data.objData.voucher.id);
            }

            // check if a selected stop point already has an invoice, if it does then abort and warn the user that
            // a stop point is already bound to an invoice
            if (stopPoint.data.objData.voucher) {
                if (stopPoint.data.objData.voucher.invoice) {
                    selectedVoucherIds = [];
                    alert(this.voucherBoundToInvoiceWarningMessage);
                    break;
                }
            }
        };

        if (selectedVoucherIds.length) {
            const invoiceObj = {
                invoices: [
                    {
                        id: null,
                        collaborator_id: this.collaboratorId,
                        due_on_datetime: moment().add(30, 'days').format('DD-MM-YYYY'), // 30 days in advance
                        vouchers: selectedVoucherIds,
                        issued_by: 1,
                        status: 1,
                        invoice_reminder_settings: []
                    }
                ]
            };

            this.http.post('api/v1/invoice', invoiceObj).pipe(take(1)).subscribe(response => {
                this.modalGridService.openInvoicesGrid();
                this.modalGridService.updateCustomerGrid();
                this.modalService.openInvoiceModal(response['items'].invoices[0]);
            });
        }
    }

    clearSelectedVouchers() {
        this.gridApi.deselectAll();
        this.selectedStopPointsCount = 0;
        this.selectedStopPointsAmount = 0;
    }

    checkIfAllZero(): Boolean {
        let allZero = true;
        this.mostVisited.forEach(element => {
            if (element !== '0') {
                allZero = false;
            }
        });
        return allZero;
    }

    getChartData() {
        this.dataLoading = true;
        let params = '?pageSize=' + this.pageSize;
        params += '&page=' + this.currentChartPage;
        params += '&date=' + moment().utc().format();
        params += '&days=' + this.selectedPeriod.value;
        if (this.currentChartPage === 0) {
            this.firstPage = true;
        } else {
            this.firstPage = false;
        }
        this.displayDummyChartValues();
        this.initChart();
    }

    chartTypeChange() {
        this.initChart();
    }

    chartPeriodChange() {
        this.currentChartPage = 0;
        this.firstPage = true;
        this.lastPage = false;
        this.emptyChartArrays();
        this.getChartData();
    }

    initChart() {
        const ctx = <HTMLCanvasElement>document.getElementById('vouchers-chart');
        const data = this.getDataForChart();
        if (this.chart) { this.chart.destroy(); }
        const chartOptions: ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptions));
        chartOptions.plugins.legend.display = true;
        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: this.months,
                    datasets: [
                        {
                            label: this.completedLabel,
                            data: this.vouchersNumberComplete,
                            //fill: true,
                            borderColor: '#00aeba',
                            backgroundColor: '#00aeba70',
                        },
                        {
                            label: this.canceledLabel,
                            data: this.vouchersNumberTotal,
                            fill: '-1',
                            borderColor: '#00aeba',
                            backgroundColor: '#FF000030',
                        },
                    ]
                },
                options: chartOptions
            });
        }
    }

    // get request (grid data)
    getVouchersGridData(pageSize) {
        let url = 'api/internal/v2/company/stop-points'
        url += '?pageSize=' + pageSize;
        url += '&page=' + this.pagesCount;
        url += '&searchQuery=' + this.searchString;
        url += '&collaboratorId=' + this.collaboratorId;
        url += this.extraFilters;
        url += '&' + this.filterType;
        url += '&' + this.selectedDatesQuery;

        return this.http.get(url);
    }

    displayDataToGrid(params, dataArray, pagesCount) {
        if (dataArray.length) {
            let lastRow = -1;
            this.pagesCount++;
            if ((dataArray.length < this.cacheBlockSize) || this.pagesCount === (pagesCount)) {
                lastRow = dataArray.length + ((this.pagesCount - 1) * this.cacheBlockSize);
            }
            params.successCallback(this.setCustomerVouchersGridData(dataArray), lastRow);

        } else {
            if (this.pagesCount) {
                const lastRow = (this.pagesCount - 1) * this.cacheBlockSize;
                params.successCallback(this.setCustomerVouchersGridData(dataArray), lastRow);
            } else {
                const noData = { noDataText: 'No data' }
                params.successCallback([noData], 1);
            }
        }
    }

    // request and pass grid data page by page
    loadSinglePage(params) {
        this.getVouchersGridData(this.cacheBlockSize).pipe(take(1)).subscribe(response => {
            this.vouchersCount = response['itemsMeta'].totalCount;
            this.projectProblemsArray = response['items']['project_problems'];
            this.customerVouchersDataArray = response['items']['stopPoints'];
            this.chargeCategories = response['items']['charge_categories'];
            this.basicVoucherServices = response['items']['basic_voucher_services'];
            this.relatedStopPointsArray = this.relatedStopPointsArray.concat(response['items']['related_stop_points']);
            this.allStopPointsObject = this.stopPointUtils.getAllStopPointsObject(this.customerVouchersDataArray, this.relatedStopPointsArray);
            this.displayDataToGrid(params, this.customerVouchersDataArray, response['itemsMeta']['pagesCount']);
            this.gridApi.hideOverlay();
        },
            (error) => {
                console.error(error);
                params.failCallback();
                this.gridApi.hideOverlay();
            }
        );
    }
    // request and pass all grid data for xls export
    loadAllPagesToExport(params) {
        const pageSize = this.bulkRequestsSize;
        if (this.vouchersCount) {
            let pagesCount = Math.ceil(this.vouchersCount / pageSize);
            if (pagesCount > this.maxRequestsToMake) { pagesCount = this.maxRequestsToMake; }
            const done = [];
            let stopPointsArray = [], chargeCategories = [], basicVoucherServices = [];
            for (let i = 0; i < pagesCount; i++) {
                done.push(false);
                this.pagesCount = i;
                this.getVouchersGridData(pageSize).pipe(take(1)).subscribe(stopsResponse => {
                    stopPointsArray = stopPointsArray.concat(stopsResponse['items']['stopPoints']);
                    chargeCategories = chargeCategories.concat(stopsResponse['items']['charge_categories']);
                    basicVoucherServices = basicVoucherServices.concat(stopsResponse['items']['basic_voucher_services']);
                    done[i] = true;
                    if (done.every(Boolean)) {
                        this.customerVouchersDataArray = stopPointsArray;
                        this.chargeCategories = chargeCategories;
                        this.basicVoucherServices = basicVoucherServices;
                        this.displayDataToGrid(params, stopPointsArray, 1);
                        this.exportToXls();
                        this.removeExportSettingsFromGrid();
                    }
                });
            }
        }
    }

    createServerSideDatasource() {
        return {
            getRows: (params) => {
                this.gridApi.showLoadingOverlay();
                this.loadSinglePage(params);
            },
        };
    }


    setCustomerVouchersGridData(stopPointsArray) {
        const projectProblemsObject = this.stopPointUtils.getProjectProblemsObject(this.projectProblemsArray);
        this.customerVouchersDataArray = []; // empty stopPoints array first
        let gridObject = {};
        this.dataService.statusPerStopPointMap = this.gridUtils.getStatusPerStopPointMap(stopPointsArray);

        if (stopPointsArray.length) {
            stopPointsArray.forEach(stopPointData => {

                let creationTimeFormatted = '-', creationDateFormatted = '';
                if (
                    stopPointData &&
                    stopPointData.voucher &&
                    stopPointData.voucher.creation_datetime
                ) {
                    creationTimeFormatted = moment(stopPointData.voucher.creation_datetime).format('HH:mm');
                    creationDateFormatted = moment(stopPointData.voucher.creation_datetime).format('DD/MM/YYYY');
                }
                else if (stopPointData.creation_datetime) {
                    creationTimeFormatted = moment(stopPointData.creation_datetime).format('HH:mm');
                    creationDateFormatted = moment(stopPointData.creation_datetime).format('DD/MM/YYYY');
                }

                let completeDatetimeMoment = null;
                if (
                    stopPointData.fulfillment_status == this.globals.stopPointFulfillmentStatusConstants['COMPLETED'] &&
                    stopPointData.solution
                ) {
                    completeDatetimeMoment = moment(stopPointData.solution.atTime);
                }

                let name = stopPointData.contact_name;
                if (name.includes(this.noNameConstant)) {
                    name = name.replace(this.noNameConstant, this.noNameLabel);
                }
                if (name.includes(this.returnConstant)) {
                    name = name.replace(this.returnConstant, this.returnLabel);
                }

                // Time (Time Windows)
                let timeWindowStart, timeWindowEnd;
                let timeWindowSecondStart, timeWindowSecondEnd;
                let timeWindowRange, timeWindowRangeMinutes;
                if (stopPointData.time_windows[0]) {
                    timeWindowStart = moment(stopPointData.time_windows[0].start, 'HH:mm:ss').format('HH:mm');
                    timeWindowRange = stopPointData.time_windows[0].time_window_range;
                    timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
                    timeWindowEnd = moment(timeWindowStart, 'HH:mm').add(timeWindowRangeMinutes, 'minutes').format('HH:mm');

                    if (stopPointData.time_windows[1]) {
                        timeWindowSecondStart = moment(stopPointData.time_windows[1].start, 'HH:mm:ss').format('HH:mm');
                        timeWindowRange = stopPointData.time_windows[1].time_window_range;
                        timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
                        timeWindowSecondEnd = moment(timeWindowSecondStart, 'HH:mm').add(timeWindowRangeMinutes, 'minutes').format('HH:mm');
                    }
                }

                // barcode
                let barcodeValue;
                if (stopPointData.service_type == this.globals.stopPointServiceTypeConstants['PICKUP'] && stopPointData.relatedStopPoint) {
                    barcodeValue = this.stopPointUtils.getBarcodeOrVoucherHash(stopPointData.relatedStopPoint);
                } else {
                    barcodeValue = this.stopPointUtils.getBarcodeOrVoucherHash(stopPointData);
                }
                const projectProblemId = stopPointData.project_problem_id ? stopPointData.project_problem_id : null;
                let projectProblemDepartureDatetime = null;
                if (projectProblemId) {
                    if (projectProblemsObject[projectProblemId]) {
                        projectProblemDepartureDatetime = projectProblemsObject[projectProblemId]['departure_datetime'];
                    }
                }

                // paid by
                let paidByValue;
                if (stopPointData.voucher) {
                    switch (stopPointData.voucher.paid_by) {
                        case this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR']:
                            paidByValue = this.consignorLabel
                            break;
                        case this.globals.stopPointCollaboratorTypesConstants['RECEIVER']:
                            paidByValue = this.receiverLabel
                            break;
                        case this.globals.stopPointCollaboratorTypesConstants['SENDER']:
                            paidByValue = this.senderLabel
                            break;
                    }
                } else {
                    paidByValue = '-';
                }

                // fulfillment status
                // (if delivery w/o pickup or independent pickup)
                let fulfillmentObj = this.gridUtils.getFulfillmentStatus(stopPointData);

                // if pickup & delivery
                if (stopPointData.related_stop_point_id &&
                    this.allStopPointsObject[stopPointData.related_stop_point_id] &&
                    stopPointData.service_type == this.globals.stopPointServiceTypeConstants['DELIVERY']) {
                    fulfillmentObj = this.gridUtils.showFulfillmentBasedOnDeliveryStatus(stopPointData, this.allStopPointsObject);
                }

                let volumeObj = this.stopPointUtils.getStopPointVolumeWeightColumnData(stopPointData);

                // packages count
                let itemsCountSum = 0;
                if (stopPointData.mass_and_dimensions?.length) {
                    stopPointData.mass_and_dimensions.forEach(item => {
                        itemsCountSum += item.count;
                    });
                }

                let addressStreet = stopPointData.address.street;
                if (stopPointData.address.houseNumber) {
                    addressStreet += ` ${stopPointData.address.houseNumber}`;
                }

                const countriesObject = countries.getNames(this.globals.currentLang, { select: 'official' });
                let senderAddressStreet = '-', senderCity = '-', senderPostalCode = '-', senderCountry = '-';
                if (stopPointData.collaborator?.address) {
                    senderCity = stopPointData.collaborator.address.city;
                    senderPostalCode = stopPointData.collaborator.address.postalCode;
                    senderCountry = countriesObject[countries.toAlpha2(stopPointData.collaborator.address.countryCode)];
                    senderAddressStreet = stopPointData.collaborator.address.street;

                    if (stopPointData.collaborator.address.houseNumber) {
                        senderAddressStreet += ` ${stopPointData.collaborator.address.houseNumber}`;
                    }
                }

                // check if can be invoiced (in manual invoicing)
                let canBeInvoiced = false;
                if (stopPointData.voucher) {
                    canBeInvoiced = this.stopPointUtils.checkVoucherCanBeInvoiced(stopPointData);
                }

                // systemic voucher tracking number
                let systemicVoucherTrackingNumber = '-';
                if (stopPointData.voucher?.company_voucher_X_systemic_courier?.tracking_number) {
                    systemicVoucherTrackingNumber = stopPointData.voucher?.company_voucher_X_systemic_courier?.tracking_number;
                }

                // fare charge
                let fareCharge = '-';
                if (stopPointData.voucher && this.globals.accessRole != this.globals.teamMemberTypeConstants['STANDARD']) {
                    fareCharge = Number(stopPointData.voucher.pay_price).toFixed(2) + this.currency;
                }

                // telephone to string to avoid scientific format
                let receiverPhone = '-';
                if (stopPointData.telephone) {
                    receiverPhone = String(stopPointData.telephone);
                }

                gridObject = {
                    id: stopPointData.id,
                    projectProblemId: projectProblemId,
                    projectProblemDepartureDatetime: projectProblemDepartureDatetime,
                    details: {
                        name: name,
                        code: barcodeValue ?? '-',
                        phone: receiverPhone,
                    },
                    address: this.addressService.getAddressLabel(stopPointData.address),
                    volumeWeight: volumeObj,
                    creation_datetime: {
                        time: creationTimeFormatted,
                        date: creationDateFormatted,
                    },
                    time: {
                        date: completeDatetimeMoment ? completeDatetimeMoment.format('DD/MM @ HH:mm') : '-',
                        twFirstStart: timeWindowStart,
                        twFirstEnd: timeWindowEnd,
                        twSecondStart: timeWindowSecondStart,
                        twSecondEnd: timeWindowSecondEnd
                    },
                    volume: this.stopPointUtils.getStopPointVolumeWeightColumnData(stopPointData),
                    status: {
                        colorClass: fulfillmentObj.colorClass,
                        label: fulfillmentObj.statusValue
                    },
                    payOnDelivery: this.gridUtils.getPayAmountStatus(stopPointData),
                    // paymentStatus: this.gridUtils.getPaymentStatus(stopPointData),
                    paymentStatusAccountingProvider: this.gridUtils.getAccountingProviderStatus(stopPointData),
                    payOnDeliveryStatusAccountingProvider: this.gridUtils.getAccountingProviderStatus(stopPointData, false),
                    canBeInvoiced: canBeInvoiced,
                    paidBy: paidByValue,
                    collaborator: stopPointData.supplier ?? stopPointData.sender_name,
                    xlsShipmentStatus: fulfillmentObj.statusValue.replace('<br /> ', ''),
                    xlsName: name,
                    xlsBarcode: barcodeValue ?? '-',
                    xlsTelephone: receiverPhone,
                    xlsDate: creationDateFormatted,
                    xlsTime: creationTimeFormatted,
                    xlsCompleteTime: completeDatetimeMoment ? completeDatetimeMoment.format('HH:mm') : '-',
                    xlsCompleteDate: completeDatetimeMoment ? completeDatetimeMoment.format('DD/MM/YYYY') : '-',
                    xlsPackagesCount: itemsCountSum,
                    xlsStreet: addressStreet,
                    xlsCity: stopPointData.address.city,
                    xlsCountry: countriesObject[countries.toAlpha2(stopPointData.address.countryCode)],
                    xlsPostalCode: stopPointData.address.postalCode,
                    xlsSenderStreet: senderAddressStreet,
                    xlsSenderCity: senderCity,
                    xlsSenderCountry: senderCountry,
                    xlsSenderPostalCode: senderPostalCode,
                    xlsFareCharge: fareCharge,
                    xlsPodStatus: this.gridUtils.getPayAmountStatus(stopPointData).label,
                    xlsPodAmount: this.gridUtils.getPayAmountStatus(stopPointData).payOnDeliveryValue,
                    xlsSystemicVoucherTrackingNumber: systemicVoucherTrackingNumber,
                    xlsVolume: String(volumeObj.load) + this.litersUnit,
                    xlsWeight: String(volumeObj.weight) + this.kilosUnit,
                    note: stopPointData.note ?? '-',
                    objData: stopPointData
                };
                this.customerVouchersDataArray.push(gridObject);
            });
        } else {
            const noData = { noDataText: 'No data' }
            this.customerVouchersDataArray.push(noData);
        }

        return this.customerVouchersDataArray;
    }

    cellEditingStarted(event) {
        this.isEditingActive = true;
    }

    cellEditingStopped(event) {
        // row click event fires right after cell edit stop event, so we add a delay to stop row click from opening SP modal
        setTimeout(() => {
            this.isEditingActive = false;
        }, 100);
    }

    checkboxRenderer(params) {
        if (params.data) {
            if (params.data.canBeInvoiced && this.isManualInvoicingActive) {
                return true;
            }
            else {
                return false;
            }
        } else {
            return false;
        }
    }

    nameCodePhoneRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().name) {
                columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
                columnObject += '<div class="double-cell standard-width">' + '<i class="fas fa-qrcode grey-letters"></i>' + params.getValue().code + ' <i class="fas fa-phone-alt grey-letters"></i> ' + params.getValue().phone;
                if (params.getValue().barcode) {
                    columnObject += ' <i class="fas fa-qrcode grey-letters"></i> ' + params.getValue().barcode + '</div>'
                }
            }
        }

        return columnObject;
    }

    // custom renderer for status
    statusRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="voucher-status-container">' + '<div class="single-cell standard-width ' + params.getValue().colorClass + '">' + params.getValue().label + '</div>' + '</div>';
        }
        return columnObject;
    }

    // custom renderer for volume
    volumeRenderer(params) {
        let columnObject = '';
        let iconPickup = `<svg viewBox='0 0 48 48' width='25' xmlns='http://www.w3.org/2000/svg'><path class="fill-evenodd light-grey-icon" d='M38 18h-8V6H18v12h-8l14 14 14-14zM10 36v4h28v-4H10z' /></svg>`;

        if (params.getValue()) {
            if (params.getValue()) {
                columnObject += '<div class="single-cell standard-width display-flex">' + iconPickup + params.getValue() + '</div>';
            }
        }
        return columnObject;
    }

    // custom renderer for time
    timeRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().twFirstStart && !params.getValue().twSecondStart) {
                columnObject += '<div class="double-cell standard-width">' + params.getValue().date + '</div>'
                columnObject += '<div class="double-cell standard-width">(' + `${params.getValue().twFirstStart}-${params.getValue().twFirstEnd}` + ')</div>'
            }
            else if (params.getValue().twFirstStart && params.getValue().twSecondStart) {
                columnObject += '<div class="double-cell standard-width">' + params.getValue().date + '</div>'
                columnObject += '<div class="double-cell standard-width">(' + `${params.getValue().twFirstStart}-${params.getValue().twFirstEnd}` + ' ' + `${params.getValue().twSecondStart}-${params.getValue().twSecondEnd}` + ')</div>'
            }
            else {
                columnObject += '<div class="single-cell standard-width">' + params.getValue().date + '</div>'
            }
        }
        return columnObject;
    }

    // FILTERS
    removeActiveClass() {
        document.querySelector('.filter-type-box.active').classList.remove('active');
    }

    filterAll() {
        if (this.isFilterChangeAllowed) {
            this.isFilterChangeAllowed = false;
            setTimeout(() => {
                this.isFilterChangeAllowed = true;
            }, 1000);

            this.clearSelectedVouchers();
            this.removeActiveClass();
            this.filterAllContainer.nativeElement.classList.add('active');

            this.filterType = '';
            this.extraFilters = '';
            this.selectedExtraFilters = [];
            this.pagesCount = 0;
            // this.gridApi.purgeServerSideCache([]);
            this.gridApi.refreshServerSideStore({ purge: true });
        }
    }

    filterHistory() {
        if (this.isFilterChangeAllowed) {
            this.isFilterChangeAllowed = false;
            setTimeout(() => {
                this.isFilterChangeAllowed = true;
            }, 1000);

            this.clearSelectedVouchers();
            this.removeActiveClass();
            this.filterHistoryContainer.nativeElement.classList.add('active');

            this.filterType = 'history=1';
            this.pagesCount = 0;
            // this.gridApi.purgeServerSideCache([]);
            this.gridApi.refreshServerSideStore({ purge: true });
        }
    }

    filterInProgress() {
        if (this.isFilterChangeAllowed) {
            this.isFilterChangeAllowed = false;
            setTimeout(() => {
                this.isFilterChangeAllowed = true;
            }, 1000);

            this.clearSelectedVouchers();
            this.removeActiveClass();
            this.filterInProgressContainer.nativeElement.classList.add('active');

            this.filterType = 'inProgress=1';
            this.pagesCount = 0;
            // this.gridApi.purgeServerSideCache([]);
            this.gridApi.refreshServerSideStore({ purge: true });
        }
    }

    filterPayOnDelivery() {
        if (this.isFilterChangeAllowed) {
            this.isFilterChangeAllowed = false;
            setTimeout(() => {
                this.isFilterChangeAllowed = true;
            }, 1000);

            this.clearSelectedVouchers();
            this.removeActiveClass();
            this.filterPayOnDeliveryContainer.nativeElement.classList.add('active');

            this.filterType = 'payOnDelivery=1';
            this.pagesCount = 0;
            // this.gridApi.purgeServerSideCache([]);
            this.gridApi.refreshServerSideStore({ purge: true });
        }
    }

    updateFilters() {    
      if (this.isFilterChangeAllowed) {  
        this.extraFilters = '';
        this.selectedExtraFilters.forEach(filter => {
          this.extraFilters += '&' + filter
        });
        this.updateGrid();
  
        this.isFilterChangeAllowed = false;
        setTimeout(() => {
          this.isFilterChangeAllowed = true;
        }, 1000);
      }
    }

    // filterToBeInvoiced() {
    //     if (this.isFilterChangeAllowed) {
    //         this.isFilterChangeAllowed = false;
    //         setTimeout(() => {
    //             this.isFilterChangeAllowed = true;
    //         }, 1000);
    //         this.clearSelectedVouchers();
    //         this.removeActiveClass();
    //         this.filterToBeInvoicedContainer.nativeElement.classList.add('active');

    //         this.filterType = 'toBeInvoiced=1';
    //         this.pagesCount = 0;
    //         // this.gridApi.purgeServerSideCache([]);
    //         this.gridApi.refreshServerSideStore({ purge: true });
    //     }
    // }

    selectAll() {
        if (!this.selectAllCheckbox) {
            this.gridApi.forEachNode((row, index) => {
                if (row.data.canBeInvoiced) {
                    this.gridApi.getRowNode(row.id).selectThisNode(true);
                }
            });
        } else {
            this.gridApi.forEachNode((row, index) => {
                this.gridApi.getRowNode(row.id).selectThisNode(false);
            });
        }

        // re-calculate sum & count on select all toggle
        this.onSelectionChanged(null);
    }

    // used for manual invoicing selections calculations (sp count & amount)
    onSelectionChanged(event) {
        this.selectedStopPointsCount = this.gridApi.getSelectedNodes().length;
        this.selectedStopPointsAmount = 0;

        this.gridApi.getSelectedNodes().forEach(selectedStopPoint => {
            if (selectedStopPoint.data.objData['voucher']) {
                if (selectedStopPoint.data.objData['voucher']['pay_price']) {
                    this.selectedStopPointsAmount += Number(selectedStopPoint.data.objData['voucher']['pay_price']);
                }
            }
        });
    }

    openXlsAction() {
        this.gridApi.paginationSetPageSize(this.vouchersCount);
        this.cacheBlockSize = this.vouchersCount;
        this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.vouchersCount);
        this.fullDataShownForExport = true;
        this.setServerSideDatasource();
    }

    rowClicked(event) {
        if (event.column.colId !== "payOnDelivery" && !this.isEditingActive) {
            if (!event.data.noDataText) {
                this.viewProjectProblemService.openStopModal(
                    event.data.id,
                    event.data.projectProblemId,
                    event.data.projectProblemDepartureDatetime,
                );
            }
        }
    }

    // export xls
    // set another data source to change between page sizes to export all items
    setServerSideDatasource() {
        const self = this;
        const dataSource = {
            rowCount: null,
            getRows: function (rowsParams) {
                setTimeout(function () {
                    self.gridApi.showLoadingOverlay();
                    if (self.fullDataShownForExport) {
                        self.loadAllPagesToExport(rowsParams);
                    } else {
                        self.loadSinglePage(rowsParams);
                    }
                }, 500);
            }
        };
        self.gridApi.setServerSideDatasource(dataSource);
    }

    // export data to xls
    exportToXls() {
        const excelParams = {
            processCellCallback: formattingFunction,
            allColumns: true,
            columnKeys: ['xlsDate', 'xlsTime', 'xlsBarcode', 'xlsSystemicVoucherTrackingNumber', 'collaborator',
                'xlsSenderStreet', 'xlsSenderPostalCode', 'xlsSenderCity', 'xlsSenderCountry',
                'xlsName', 'xlsStreet', 'xlsPostalCode', 'xlsCity', 'xlsCountry', 'xlsTelephone',
                'xlsCompleteDate', 'xlsCompleteTime', 'xlsPackagesCount', 'xlsWeight', 'xlsVolume',
                'xlsFareCharge', 'xlsPodAmount', 'xlsPodStatus', 'xlsShipmentStatus',
                'paidBy', 'note'],
            fileName: this.genericUtils.removeDotsFromString('lastmily_export')
        };
        function formattingFunction(params) {
            if (params.value) {
                if (params.value.name || params.value.name === '') {
                    let nameValue = params.value.name;
                    if (params.value.customerId) {
                        nameValue += " - " + params.value.customerId;
                    }
                    return nameValue;
                } else if (params.value.load || params.value.weight) {
                    let label = '';
                    if (params.value.load) {
                        label += params.value.load + 'lt '
                    }
                    if (params.value.weight) {
                        label += params.value.weight + 'kg '
                    }
                    return label;
                } else if (params.value.time) {
                    return params.value.time + ' ' + params.value.date;
                } else if (params.value.date) {
                    return params.value.date;
                } else if (params.value.payOnDeliveryValue) {
                    return params.value.payOnDeliveryValue;
                } else if (params.value.label) {
                    return params.value.label;
                } else if (params.value.barcode) {
                    return params.value.barcode;
                } else {
                    return params.value;
                }
            }
        }
        this.gridApi.exportDataAsExcel(excelParams);
    }

    // restore the grid settings for normal use
    removeExportSettingsFromGrid() {
        this.gridApi.paginationSetPageSize(this.defaultPaginationSize);
        this.cacheBlockSize = this.defaultPaginationSize;
        this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.defaultPaginationSize);
        this.fullDataShownForExport = false;
        this.pagesCount = 0;
        this.setServerSideDatasource();
    }


    onFirstDataRendered(params) { }

    createdDateRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            if (params.getValue().name) {
                columnObject += '<div class="double-cell bold-letters standard-width" title="' + params.getValue().name + '">' + params.getValue().name + '</div>';
                columnObject += '<div class="double-cell"><i class="fas fa-phone-alt"></i> ' + params.getValue().phone;
            }
            if (params.getValue().customerId) {
                columnObject += ' <i class="fas fa-fingerprint"></i> ' + params.getValue().customerId + '</div>';
            } else {
                columnObject += '</div>';
            }
        }
        return columnObject;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    initGrid(id) {
        this.pagesCount = 0;
        let intervalId = setInterval(() => {
            if (this.gridApi) {
                this.collaboratorId = id;
                this.gridApi.setServerSideDatasource(this.createServerSideDatasource());
                clearInterval(intervalId);
            }
        }, 100);
    }

    getTranslations() {
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.NAME_CODE').subscribe((res: string) => { this.nameCodeTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.ADDRESS').subscribe((res: string) => { this.addressTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.TIME_CREATED').subscribe((res: string) => { this.timeTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.COMPLETE_TIME').subscribe((res: string) => { this.completeTimeTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.COMPLETE_DATE').subscribe((res: string) => { this.completeDateTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.SYSTEMIC_VOUCHER_TRACKING_NUMBER').subscribe((res: string) => { this.systemicVoucherTrackingNumberTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.DATE_CREATED').subscribe((res: string) => { this.createdTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.COMPLETION_TIME').subscribe((res: string) => { this.completeTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.VOLUME_WEIGHT').subscribe((res: string) => { this.volumeTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.VOLUME').subscribe((res: string) => { this.loadTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.MASS').subscribe((res: string) => { this.weightTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.SHIPMENT_STATUS').subscribe((res: string) => { this.shipmentStatusTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.PAY_ON_DELIVERY').subscribe((res: string) => { this.payOnDeliveryTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.PAY_ON_DELIVERY_STATUS').subscribe((res: string) => { this.payOnDeliveryStatusTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.PAYMENT_AMOUNT').subscribe((res: string) => { this.paymentAmountTitle = res; }));
        // this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.PAYMENT_STATUS').subscribe((res: string) => { this.paymentStatusTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.PROVIDER_PAYMENT_STATUS').subscribe((res: string) => { this.paymentStatusAccountingProviderTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.PROVIDER_COLLECTION_PAYMENT_STATUS').subscribe((res: string) => { this.payOnDeliveryStatusAccountingProviderTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));
        this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.RECEIVER_NAME').subscribe((res: string) => { this.receiverTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.RETURN').subscribe((res: string) => { this.returnLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.STATUS_COMPLETED').subscribe((res: string) => { this.completedLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.STATUS_CANCELED').subscribe((res: string) => { this.canceledLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.INVOICE_BOUND_WARNING').subscribe((res: string) => { this.voucherBoundToInvoiceWarningMessage = res; }));
        this.listen.push(this.translate.get('STOP_POINT.RECEIVER_ADDRESS').subscribe((res: string) => { this.streetTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.RECEIVER_CITY').subscribe((res: string) => { this.cityTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.RECEIVER_POSTAL_CODE').subscribe((res: string) => { this.postalCodeTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.RECEIVER_COUNTRY').subscribe((res: string) => { this.countryTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.SENDER_CITY').subscribe((res: string) => { this.senderCityTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.SENDER_COUNTRY').subscribe((res: string) => { this.senderCountryTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.SENDER_ADDRESS').subscribe((res: string) => { this.senderStreetTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.SENDER_POSTAL_CODE').subscribe((res: string) => { this.senderPostalCodeTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.DATE_CREATED').subscribe((res: string) => { this.dateTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.COUNT').subscribe((res: string) => { this.packagesCountTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.FUEL_SURCHARGE').subscribe((res: string) => { this.fuelSurchargeTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.VAT').subscribe((res: string) => { this.taxTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.TOTAL_CHARGE').subscribe((res: string) => { this.totalChargeTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.FARE_CHARGE').subscribe((res: string) => { this.fareChargeTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.RECEIVER_PHONE').subscribe((res: string) => { this.telephoneTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.PAID_BY').subscribe((res: string) => { this.paidByTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.NOTE').subscribe((res: string) => { this.noteTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.CONSIGNOR').subscribe((res: string) => { this.consignorLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.RECEIVER').subscribe((res: string) => { this.receiverLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.SENDER_NAME').subscribe((res: string) => { this.senderLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.SERVICES').subscribe((res: string) => { this.servicesTitle = res; }));
        this.listen.push(this.translate.get('CUSTOMER_INVOICES.SERVICES_CHARGE').subscribe((res: string) => { this.servicesChargeTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.VOLUME_WEIGHT').subscribe((res: string) => { this.volumeWeightTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.LITERS_UNIT').subscribe((res: string) => { this.litersUnit = res; }));
        this.listen.push(this.translate.get('GENERIC.KILOS').subscribe((res: string) => { this.kilosUnit = res; }));
        if (this.globals.accountingProvider) {
          this.listen.push(this.translate.get('STOP_POINT').subscribe((res: string) => {
            this.extraFiltersOptions = [
              {
                caption: res['FILTER_SHOW_COD'],
                key: 'Cod=1'
              },
              {
                caption: res['FILTER_PROVIDER_NO_INVOICE'],
                key: 'invoice_accounting_provider_statuses_negative=1,5'
              },
              {
                caption: res['FILTER_PROVIDER_NO_COLLECTION'],
                key: 'collection_accounting_provider_statuses_negative=1,5'
              },
            ];
          }));
        }

        this.getChartData();

        this.columnDefs = [
            {
                headerName: this.nameCodeTitle,
                field: 'details',
                cellRenderer: this.nameCodePhoneRenderer,
                checkboxSelection: this.checkboxRenderer.bind(this),
                width: this.gridsService.widthCalculator(20)
            },
            // exists to print barcode in a separate column on xls export
            {
                headerName: 'Barcode',
                field: 'details.code',
                hide: true
            },
            {
                headerName: this.addressTitle,
                field: 'address',
                cellRenderer: this.gridsService.addressRenderer,
                width: this.gridsService.widthCalculator(16)
            },
            {
                headerName: this.createdTitle,
                field: 'creation_datetime',
                cellRenderer: this.gridsService.dateTimeRenderer,
                width: this.gridsService.widthCalculator(8)
            },
            {
                headerName: this.completeTitle,
                field: 'time',
                cellRenderer: this.timeRenderer,
                width: this.gridsService.widthCalculator(9)
            },
            {
                headerName: this.volumeTitle,
                field: 'volume',
                // cellRenderer: this.volumeRenderer,
                cellRenderer: this.gridsService.volumeWeightCountRenderer,
                width: this.gridsService.widthCalculator(7)
            },
            {
                headerName: this.paymentStatusAccountingProviderTitle,
                field: 'paymentStatusAccountingProvider',
                sortable: false,
                cellRenderer: this.gridsService.paymentStatusRenderer.bind(this),
                width: this.gridsService.widthCalculator(10)
            },
            // {
            //     headerName: this.paymentStatusTitle,
            //     field: 'paymentStatus',
            //     cellRenderer: this.gridsService.paymentStatusRenderer.bind(this),
            //     width: this.gridsService.widthCalculator(14)
            // },
            {
                headerName: this.payOnDeliveryTitle,
                field: 'payOnDelivery',
                cellRenderer: this.gridsService.payOnDeliveryRenderer,
                cellEditorFramework: PaymentStatusSelectEditorComponent,
                editable: (params) => this.gridsService.isStopPointRowWithPayAmount(params),
                width: this.gridsService.widthCalculator(10),
            },
            {
                headerName: this.payOnDeliveryStatusAccountingProviderTitle,
                field: 'payOnDeliveryStatusAccountingProvider',
                sortable: false,
                cellRenderer: this.gridsService.paymentStatusRenderer.bind(this),
                width: this.gridsService.widthCalculator(10)
            },
            {
                headerName: this.shipmentStatusTitle,
                field: 'status',
                cellRenderer: this.statusRenderer,
                width: this.gridsService.widthCalculator(10)
            },
            {
                headerName: this.shipmentStatusTitle,
                field: 'xlsShipmentStatus',
                hide: true
            },
            // exists to print POD status
            {
                headerName: this.payOnDeliveryStatusTitle,
                field: 'xlsPodStatus',
                hide: true
            },
            // exists to print POD amount
            {
                headerName: this.payOnDeliveryTitle,
                field: 'xlsPodAmount',
                hide: true
            },
            {
                headerName: this.receiverTitle,
                field: 'xlsName',
                hide: true
            },
            {
                headerName: 'Barcode',
                field: 'xlsBarcode',
                hide: true
            },
            {
                headerName: this.systemicVoucherTrackingNumberTitle,
                field: 'xlsSystemicVoucherTrackingNumber',
                hide: true,
            },
            {
                headerName: this.telephoneTitle,
                field: 'xlsTelephone',
                hide: true
            },
            {
                headerName: this.streetTitle,
                field: 'xlsStreet',
                hide: true
            },
            {
                headerName: this.cityTitle,
                field: 'xlsCity',
                hide: true
            },
            {
                headerName: this.postalCodeTitle,
                field: 'xlsPostalCode',
                hide: true
            },
            {
                headerName: this.countryTitle,
                field: 'xlsCountry',
                hide: true
            },
            {
                headerName: this.senderPostalCodeTitle,
                field: 'xlsSenderPostalCode',
                hide: true
            },
            {
                headerName: this.senderStreetTitle,
                field: 'xlsSenderStreet',
                hide: true
            },
            {
                headerName: this.senderCityTitle,
                field: 'xlsSenderCity',
                hide: true
            },
            {
                headerName: this.senderCountryTitle,
                field: 'xlsSenderCountry',
                hide: true
            },
            {
                headerName: this.dateTitle,
                field: 'xlsDate',
                hide: true
            },
            {
                headerName: this.timeTitle,
                field: 'xlsTime',
                hide: true
            },
            {
                headerName: this.completeDateTitle,
                field: 'xlsCompleteDate',
                hide: true
            },
            {
                headerName: this.completeTimeTitle,
                field: 'xlsCompleteTime',
                hide: true
            },
            {
                headerName: this.loadTitle,
                field: 'xlsVolume',
                hide: true
            },
            {
                headerName: this.weightTitle,
                field: 'xlsWeight',
                hide: true
            },
            {
                headerName: this.packagesCountTitle,
                field: 'xlsPackagesCount',
                hide: true
            },
            {
                headerName: this.fareChargeTitle,
                field: 'xlsFareCharge',
                hide: true
            },
            {
                headerName: this.packagesCountTitle,
                field: 'xlsPackagesCount',
                hide: true
            },
            {
                headerName: this.senderLabel,
                field: 'collaborator',
                hide: true
            },
            {
                headerName: this.paidByTitle,
                field: 'paidBy',
                hide: true
            },
            {
                headerName: this.noteTitle,
                field: 'note',
                hide: true
            },
        ];
    }

    ngOnInit(): void {
        // allow filter change after 1s (grid fix)
        setTimeout(() => {
            this.isFilterChangeAllowed = true;
        }, 1000);

        this.charts = [
            {
                label: 'Vouchers',
                value: 'vouchers',
            },
        ];
        this.periods = this.chartService.periods;
        this.selectedChart = this.charts[0];
        this.selectedPeriod = this.periods[4];

        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((text: string) => {
                this.pagesCount = 0;
                // this.gridApi.purgeServerSideCache([]);
                this.gridApi.refreshServerSideStore({ purge: true });
            });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}