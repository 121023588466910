import { Component, OnInit, OnDestroy, Input, Injector, AfterViewInit } from '@angular/core';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LmTableBaseColumnDefs } from '@app/model/table-column-defs';
import { AddButtonBaseConfig as abbc } from '@app/model/buttons-model';
import { LmNotificationService } from '@app/core/services/notification.service';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { SettingsPriceListsViewModelService } from './settings-price-lists-viewmodel.service';
import { LmModelProxyService } from '@app/core/services/model-proxy/model-proxy.service';
import { PriceListModalComponent } from './new-price-list-modal/new-price-list-modal.component';
import { concatMap, tap } from 'rxjs';
import { SettingsAddonServicesViewModelService } from '../settings-services/settings-addon-services-viewmodel.service';
import { ISettings_Addon_Service_Dto } from '@app/api/models/settings-services-dto';
import _ from 'lodash';
import { SettingsBasicServicesViewModelService } from '../settings-services/settings-basic-services-viewmodel.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'price-lists',
    template: `<lm-table-list [cols]="cols" [VM]="VM" [headerBtn]="headerBtn" [title]="title"></lm-table-list>`,
    providers: [SettingsPriceListsViewModelService, LmModelProxyService, SettingsAddonServicesViewModelService, SettingsBasicServicesViewModelService]
})
export class PriceListsComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() title: string;
    priceListModal = PriceListModalComponent;
    cols: LmTableBaseColumnDefs[];
    headerBtn: LmButtonConfig;
    translations;
    listen = [];
    count = 0;

    constructor(
        public VM: SettingsPriceListsViewModelService,
        private addonServicesVM: SettingsAddonServicesViewModelService,
        private basicServicesVM: SettingsBasicServicesViewModelService,
        private notificationSvc: LmNotificationService, 
        public translate: TranslateService,
        public globals: Globals,  
        private activatedRoute: ActivatedRoute,
        private _injector: Injector
    ){
      this.listen.push(this.activatedRoute.params.subscribe(params => {
        if (params['id']) this.VM.collaboratorId = params['id'];
      }));
      this.headerBtn = {...abbc, command: this.showPriceListModal};
      this.translate.get('PRICE_LIST').pipe(
          tap(response => {
            this.cols = [
              {header: response['NAME_SERVICES'], field: 'nameCaption', cellClass:""},
              {header: response['REGION'], field: 'areasCaption', cellClass:""},
              {header: response['DISTANCE_HEADER'], field: 'distanceΤypeCaption', cellClass:""},
              {header: response['TYPE'], field: 'typeCaption', cellClass:""},
            ]; 
              this.VM.modalConfig.data['translations'] = response;
          })
      ).subscribe(); 
      this.translate.get('WARNINGS').pipe(
          tap(response => {
              this.VM.confirmBeforeDeleteText = response['DELETE_CONFIRM'];
          })
      ).subscribe();     
      this.count = this.VM.model?.length ?? 0;
    }

    private getEmptyItemForModal(additionalCharges:ISettings_Addon_Service_Dto[]){
      const item = _.cloneDeep(this.VM.newItem());
      additionalCharges.forEach(charge => {
        item.addon_services.push({
          value: charge.value,
          name: charge.name,
          id: charge.id,
          disabledCheckbox: charge.type !== this.globals.priceListAddonServiceTypeEnum['ManualService'].key,
          hideDelete: true //charge.type !== this.globals.priceListAddonServiceTypeEnum['ManualService'].key
        });
      });
      if (this.VM.collaboratorId) item.collaborator_id = Number(this.VM.collaboratorId);
      return item;
    }

    showPriceListModal = () => {
      this.addonServicesVM.getRows().pipe(
        concatMap(additionalCharges => {
          return this.notificationSvc.showDialog<boolean>({
            componentType: this.priceListModal,
            injector: this._injector,
            data: {
              VM: this.VM,
              globals: this.globals,
              item: this.getEmptyItemForModal(additionalCharges['items']),
              translations: this.translations,
            },
          });
        })
      ).subscribe();
    }

    ngAfterViewInit(): void { }
    ngOnInit() {
      this.basicServicesVM.getRows().subscribe(response => {
        this.VM.defaultNextDayService = response.items.find(service => service.type == this.globals.priceListServicesEnum.NextDay.key && service.is_default)
      });
    }
    ngOnDestroy() {
      this.listen.forEach(element => {
        element.unsubscribe();
      });
    }
}
