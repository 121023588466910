import { GenericService } from '../services/generic.service';
import { Component, ViewChild, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollaboratorService } from '@app/services/collaborator.service';
import { Globals } from '@app/services/globals';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PartnerShipmentsGridComponent } from '@app/grids/partner-shipments-grid/partner-shipments-grid.component';
import { CollaboratorPaymentsGridComponent } from '@app/grids/collaborator-payments-grid/collaborator-payments-grid.component';
import { CollaboratorInvoicesGridComponent } from '@app/grids/collaborator-invoices-grid/collaborator-invoices-grid.component';
import { PartnerModalComponent } from '@app/modals/partner-modal/partner-modal.component';

@Component({
    selector: 'app-collaborator-partner-view',
    templateUrl: './collaborator-partner-view.component.html',
    styleUrls: ['./collaborator-partner-view.component.scss']
})
export class CollaboratorPartnerViewComponent implements OnInit, OnDestroy {
    @ViewChild('container', { static: false }) container;
    @ViewChild('invoices', { static: false }) invoices;
    @ViewChild('shipments', { static: false }) shipments;
    @ViewChild('payments', { static: false }) payments;
    @ViewChild('openShipmentsChartsButton', { static: false }) openShipmentsChartsButton;
    @ViewChild('openShipmentsFiltersButton', { static: false }) openShipmentsFiltersButton;
    @ViewChild('openInvoicesButton', { static: false }) openInvoicesButton;
    @ViewChild('openPaymentsButton', { static: false }) openPaymentsButton;

    @ViewChild(PartnerModalComponent, { static: false }) partnerModalComponent: PartnerModalComponent;
    @ViewChild(PartnerShipmentsGridComponent, { static: false }) partnerShipmentsGridComponent: PartnerShipmentsGridComponent;
    @ViewChild(CollaboratorInvoicesGridComponent, { static: false }) collaboratorInvoicesGridComponent: CollaboratorInvoicesGridComponent;
    @ViewChild(CollaboratorPaymentsGridComponent, { static: false }) collaboratorPaymentsGridComponent: CollaboratorPaymentsGridComponent;

    gridApi;
    gridColumnApi;
    pagesCount = 0;
    cacheBlockSize = 50;
    searchTextChanged = new Subject<string>();
    searchString: String = '';

    // collaboratorId;
    collaboratorData;
    collaboratorId = null;
    collaboratorName;
    collaboratorCode;
    partnerLogo;

    partners = [];

    listen = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        public router: Router,
        private http: HttpClient,
        private collaboratorService: CollaboratorService,
        public globals: Globals,
        private genericService: GenericService
    ) {
        // detect navigation end to remove active partners class
        this.listen.push(router.events.subscribe(
            (event) => {
                if (event instanceof NavigationEnd && this.router.url.split('/')[1] != 'partnerView') {
                    const navElement = document.getElementById('partners-navbar');
                    if (navElement) {
                        navElement.classList.remove('active-link');
                    }
                }
            }
        ));
        this.listen.push(this.genericService.listenSubmitPartner().subscribe(() => {
            this.updateCollaboratorData();
        }));
    }

    updateCollaboratorData() {
        // collaborator portal
        if (this.globals.collaboratorModeEnabled) {
            this.collaboratorData = this.globals.partners[this.collaboratorId];
            this.collaboratorService.collaboratorData = this.collaboratorData;

            this.collaboratorName = this.collaboratorData.name;

            this.collaboratorCode = this.collaboratorData.id;
            this.collaboratorCode = this.collaboratorService.generateIdWithZeros(this.collaboratorCode);

            this.partnerLogo = this.collaboratorData.logo['base64'];

            setTimeout(() => {
                this.partnerShipmentsGridComponent.initGrid(this.collaboratorId);
                this.collaboratorInvoicesGridComponent.initGrid(this.collaboratorId);
                this.collaboratorPaymentsGridComponent.initGrid(this.collaboratorId);
                this.partnerModalComponent.collaboratorId = this.collaboratorId;
            }, 500)
        }

        // partner portal (through courier)
        else {
            this.collaboratorData = this.globals.partners[this.collaboratorId];
            this.collaboratorService.collaboratorData = this.collaboratorData;

            this.collaboratorName = this.collaboratorData.companyCollaborator.collaborator.collaboratorData.collaborator_name;
            this.collaboratorCode = this.collaboratorService.generateIdWithZeros(this.collaboratorId);

            if (this.collaboratorData.companyCollaborator.partnerCompany) {
                this.partnerLogo = this.collaboratorData.companyCollaborator.partnerCompany.companyLogo['base64'];
            }

            setTimeout(() => {
                this.partnerShipmentsGridComponent.initGrid(this.collaboratorId);
                this.collaboratorInvoicesGridComponent.initGrid(this.collaboratorId);
                this.collaboratorPaymentsGridComponent.initGrid(this.collaboratorId);
                this.partnerModalComponent.collaboratorId = this.collaboratorId;
            }, 500);
        }
    }

    setCollaboratorData() {
        // let url = 'api/v1/company-collaborators/' + this.collaboratorId + '?partnerType=' + this.globals.companyModes['COLLABORATOR'];
        let url;
        if (this.globals.collaboratorModeEnabled) {
            url = 'api/v1/partner/' + this.collaboratorId;
        } else {
            url = 'api/v1/company-collaborators/' + this.collaboratorId;
        }


        this.http.get(url).pipe(take(1)).subscribe(response => {
            // collaborator portal
            if (this.globals.collaboratorModeEnabled) {
                if (response && response['items']) {
                    // this.collaboratorData = this.globals.partnersByCollaboratorId[this.collaboratorId];
                    this.collaboratorData = response['items'][0];
                    this.collaboratorService.collaboratorData = this.collaboratorData;
                    const partnerId = this.collaboratorData['companyCollaborator']['connection']['company_id'];

                    this.collaboratorName = this.globals.partners[partnerId].name;
                    this.collaboratorCode = this.globals.partners[partnerId].id;
                    this.collaboratorCode = this.collaboratorService.generateIdWithZeros(this.collaboratorCode);

                    if (this.globals.partners[partnerId]['logo']) {
                        this.partnerLogo = this.globals.partners[partnerId]['logo']['base64'];
                    }

                    setTimeout(() => {
                        this.collaboratorInvoicesGridComponent.collaboratorId = this.collaboratorId;
                        this.collaboratorPaymentsGridComponent.collaboratorId = this.collaboratorId;

                        this.partnerShipmentsGridComponent.initGrid(this.collaboratorId);
                        this.collaboratorInvoicesGridComponent.initGrid(this.collaboratorId);
                        this.collaboratorPaymentsGridComponent.initGrid(this.collaboratorId);
                        this.partnerModalComponent.collaboratorId = this.collaboratorId;
                    }, 500);
                }
            }
            // normal partner portal
            else {
                if (response['items']) {
                    // this.collaboratorData = this.globals.partners[this.collaboratorId];
                    this.collaboratorData = response['items'][0];
                    this.collaboratorService.collaboratorData = this.collaboratorData;

                    this.collaboratorName = this.globals.partners[this.collaboratorId].name;
                    this.collaboratorCode = this.collaboratorService.generateIdWithZeros(this.collaboratorId);

                    if (this.globals.partners[this.collaboratorId]) {
                        if (this.globals.partners[this.collaboratorId]['logo']) {
                            this.partnerLogo = this.globals.partners[this.collaboratorId]['logo']['base64'];
                        }
                    }

                    setTimeout(() => {
                        this.collaboratorInvoicesGridComponent.collaboratorId = this.collaboratorId;
                        this.collaboratorPaymentsGridComponent.collaboratorId = this.collaboratorId;

                        this.partnerShipmentsGridComponent.initGrid(this.collaboratorId);
                        this.collaboratorInvoicesGridComponent.initGrid(this.collaboratorId);
                        this.collaboratorPaymentsGridComponent.initGrid(this.collaboratorId);
                        this.partnerModalComponent.collaboratorId = this.collaboratorId;
                    }, 500);
                }
            }
        });
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    backButtonClicked() {
        if (this.globals.collaboratorModeEnabled) {
            this.router.navigateByUrl('/partners');
        }
        else {
            this.router.navigateByUrl('/partnerView/partners');
        }
    }

    removeActiveClass() {
        document.querySelector('.white-bar-right-button.active').classList.remove('active');
    }

    openPartnersModal() {
        this.partnerModalComponent.openModal();

        this.partnerModalComponent.partnerFormComponent.loadPartnersData(this.collaboratorData);
    }

    openInvoicesGrid() {
        this.openCharts();

        this.invoices.nativeElement.classList.remove('hidden');
        this.shipments.nativeElement.classList.add('hidden');
        this.payments.nativeElement.classList.add('hidden');

        this.removeActiveClass();
        this.openInvoicesButton.nativeElement.classList.add('active');
    }

    openShipmentsGridWithChart() {
        this.openCharts();

        this.invoices.nativeElement.classList.add('hidden');
        this.shipments.nativeElement.classList.remove('hidden');
        this.payments.nativeElement.classList.add('hidden');

        this.removeActiveClass();
        this.openShipmentsChartsButton.nativeElement.classList.add('active');
    }

    openShipmentsGridWithFilters() {
        this.openFilters();

        this.invoices.nativeElement.classList.add('hidden');
        this.shipments.nativeElement.classList.remove('hidden');
        this.payments.nativeElement.classList.add('hidden');

        this.removeActiveClass();
        this.openShipmentsFiltersButton.nativeElement.classList.add('active');
    }

    openPaymentsGrid() {
        this.openCharts();

        this.invoices.nativeElement.classList.add('hidden');
        this.shipments.nativeElement.classList.add('hidden');
        this.payments.nativeElement.classList.remove('hidden');

        this.removeActiveClass();
        this.openPaymentsButton.nativeElement.classList.add('active');
    }

    // activates/deactivates charts for all components
    openCharts() {
        this.partnerShipmentsGridComponent.openChart();
        this.collaboratorInvoicesGridComponent.openChart();
        this.collaboratorPaymentsGridComponent.openChart();
        this.partnerShipmentsGridComponent.closeFilters();

        this.removeActiveClass();
        this.openShipmentsChartsButton.nativeElement.classList.add('active');
    }

    openFilters() {
        this.collaboratorInvoicesGridComponent.closeChart();
        this.partnerShipmentsGridComponent.closeChart();
        this.collaboratorPaymentsGridComponent.closeChart();
        this.partnerShipmentsGridComponent.openFilters();

        this.removeActiveClass();
        this.openShipmentsFiltersButton.nativeElement.classList.add('active');
    }

    openMap() {
        this.globals.comingSoonAlert();
    }

    ngOnInit() {
        // send 'collaboratorId' to vouchers, invoices & payments grid components
        this.listen.push(this.activatedRoute.params.subscribe(params => {
            this.collaboratorId = params['id'];
            this.setCollaboratorData();
        }));

        // this.setPartnerInformation();

        // fix for active navbar icon
        if (this.router.url.split('/')[2] == 'collaboratorPartnerView') {
            const navElement = document.getElementById('partners-navbar');
            if (navElement) {
                navElement.classList.add('active-link');
            }
        }


        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((text: string) => {
                if (this.openInvoicesButton.nativeElement.classList.contains('active')) {
                    this.collaboratorInvoicesGridComponent.searchString = this.searchString;
                    this.collaboratorInvoicesGridComponent.updateGrid();
                }
                else if (this.openPaymentsButton.nativeElement.classList.contains('active')) {
                    this.collaboratorPaymentsGridComponent.searchString = this.searchString;
                    this.collaboratorPaymentsGridComponent.updateGrid();
                }
                else if (this.openShipmentsChartsButton.nativeElement.classList.contains('active')
                    || this.openShipmentsFiltersButton.nativeElement.classList.contains('active')) {
                    this.partnerShipmentsGridComponent.searchString = this.searchString;
                    this.partnerShipmentsGridComponent.updateGrid();
                }
            });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
