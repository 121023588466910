export const INITIAL_DATA = {
    newBasicServiceItem: {
        name: undefined, 
        type: undefined, 
        is_default: false
    },
    newAddonServiceItem: {
        name: undefined, 
        type: undefined, // this gets value 4(ManualService) in SettingsAddonServicesViewModelService onInit 
        value: undefined, 
        vat: 24,
        operator: '+'
    },
    newFuelSurchargeItem: {
        name: '',
        collaborator_id: undefined,
        is_collaborator_specific: undefined,
		type: undefined,
		value: undefined,
		vat: 24,
		operator: '%',
        is_generic: true,
        service_ids: []
    },
    newSurchargeItem: {
        name: undefined,
		type: undefined,
		value: undefined,
		vat: 24,
		operator: '%'
    },
    newPriceListItem: {
        name: undefined,
        collaborator_id: undefined,
		type: [],
		distance_type: [],
        services: [],
        gov_service_id: null,
		area_groups: {from: {id: undefined, name: undefined}, to: {id: undefined, name: undefined}},
        weight_charges: [],
        item_charges: [],
        distance_charges: [],
        addon_services: [],
        systemic_courier: null,
    },
    newChargeTableRowItem: {
        type: undefined,
        charge_value: undefined, 
        name: undefined, 
        price_value: undefined,
        is_additional: false
    },
    newServiceChargeTableRowItem: {
        name: undefined, 
        value: undefined,
        type: undefined,
        is_generic: false,
        vat: 24,
        operator: '+'
    },
    newRevaluationItem: {
        company_id: undefined,
        collaborator_id: undefined,
        is_collaborator_specific: undefined,
        is_general: false, 
        categories: undefined,
        value: undefined,
        value_type: undefined,
        operator: undefined,
        applicable_price_list_type: null,
        creation_datetime: undefined,
        revaluationType: {operator: undefined, selection: undefined}
    },
    newTransportationRegionsItem: {
        id: undefined,
        company_id: undefined,
        name: undefined,
        administrative_level: undefined,
        is_general: undefined,
        bounding_box: undefined,
        square_meters: undefined,
        creation_datetime: undefined,
        areas: [],
        area_names: undefined
    },
    newDiscountItem: {
        id: undefined,
        name: undefined,
        collaborator_id: undefined,
        percentage: undefined,
        creation_datetime: undefined,
        price_lists: []
    },
    newPudoCompanyItem:{
        company: {
            name: undefined,
            email: undefined,
            telephone: undefined,
            tin: undefined,
            tax_office : undefined,
            contact_name : undefined,
            partner_token: null,
            website: undefined
        },
        connection: {
            courier_company_id : undefined,
            max_expire_days : undefined,
            is_active : 1,
            iban: undefined,
            billing_address: undefined,
            bank_name: undefined,
            info_message: undefined, 
            info_message_enabled: undefined
        },
        companySettings : {
        },
        userProfile: {
            name: undefined,
            email: undefined
        }
    },
    newPudoPointItem:{
        id: null,
        is_active: true,
        capacity: undefined,
        address: {
            id: null,
            countryCode: '',
            state: '',
            county: '',
            city: '',
            district: '',
            street: '',
            street2: '',
            houseNumber: '',
            postalCode: '',
            isPlace: undefined,
            placeName: undefined,
            value: undefined,
            lat: undefined,
            lon: undefined,
            term: '',
        },
        telephone: {
            id: null,
            label: 'Phone',
            telephone_number: undefined,
            telephone_code: undefined
        },
        description: '',
        has_parking: undefined,
        pudo_point_working_hours:[] 
    },
    newPudoPointOfficeMemberItem:{
        id: null,
        company_id: undefined,
        driver_ids: undefined,
        depot_ids: undefined,
        name: undefined,
        email: undefined,
        user_name: undefined,
        telephone: undefined,
        access_group: '1',
        roles: undefined,
    },
    newPudoCompanySettingsItem:{
        id: null,
        name: undefined,
        description: undefined,
        website: undefined,
        telephone: undefined,
        email: undefined,
        tax_office: undefined,
        tin: undefined,
        registry_number: undefined,
        invoice_notes: undefined,
        auto_invoicing: undefined,
    },
    barChartDefaultPalette: [
        '#00aeba', 
        '#741b47', 
        '#134f5c', 
        '#b45f06', 
        '#0b5393', 
        '#274e13', 
        '#7f6000', 
        '#5b0f00', 
        '#351c76', 
        '#e69138'
    ],
    newDepotItem:{
        id: null,
        company_depot_identification_number: undefined,
        countryPrefix: '',
        phoneNumber: null,
        telephone: '',
        address: {
            id: null,
            countryCode: '',
            state: '',
            county: '',
            city: '',
            district: '',
            street: '',
            street2: '',
            houseNumber: '',
            postalCode: '',
            isPlace: undefined,
            placeName: undefined,
            value: undefined,
            lat: undefined,
            lon: undefined,
            term: '',
            label: ''
        }
    },
    defaultTheme:{
        color1: '#00aeba',
        color2: '#00aeba'
    }
}