import { DepotUtils } from '@app/utils/depot-utils';
import { GenericService } from '@app/services/generic.service';
import { PhotoModalComponent } from './../photo-modal/photo-modal.component';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import * as moment from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AddressService } from '@app/services/address.service';
import { CancelStopPointModalComponent } from '@app/modals/cancel-stop-point-modal/cancel-stop-point-modal.component';
import { SignatureModalComponent } from '@app/modals/signature-modal/signature-modal.component';
import { CustomerModalComponent } from '@app/modals/customer-modal/customer-modal.component';
import { StopPointHistoryGridComponent } from '@app/grids/stop-point-history-grid/stop-point-history-grid.component';
import { Router } from '@angular/router';
import { StopPointService } from '@app/services/stop-point.service';
import { take } from 'rxjs/operators';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { ModalService } from '@app/services/modal.service';
import { ModalGridService } from '@app/services/modal-grid.service';
import { MilyService } from '@app/services/mily.service';
import { ProgressTimelineComponent } from '@app/progress-timeline/progress-timeline.component';
import { ProgressTimelineFoodComponent } from '@app/progress-timeline-food/progress-timeline-food.component';
import { FulfillmentUtils } from '@app/utils/fulfillment-event-utils';
import { ImageUtils } from '@app/utils/image-utils';

@Component({
  selector: 'app-stop-modal',
  templateUrl: './stop-modal.component.html',
  styleUrls: ['./stop-modal.component.scss']
})
export class StopModalComponent implements OnInit, OnDestroy {
  @ViewChild('modal', { static: false }) modal;
  @ViewChild('speedexHistory', { static: false }) speedexHistory!: ElementRef;
  @ViewChild('speedexHistoryBody', { static: false }) speedexHistoryBody!: ElementRef;
  @ViewChild('navigationArrow', { static: false }) navigationArrow;
  @ViewChild(CancelStopPointModalComponent, { static: false }) cancelStopPointModalComponent: CancelStopPointModalComponent;
  @ViewChild(SignatureModalComponent, { static: false }) signatureModalComponent: SignatureModalComponent;
  @ViewChild(PhotoModalComponent, { static: false }) photoModalComponent: PhotoModalComponent;
  @ViewChild(CustomerModalComponent, { static: false }) customerModalComponent: CustomerModalComponent;
  @ViewChild(StopPointHistoryGridComponent, { static: false }) historyGridComponent: StopPointHistoryGridComponent;
  @ViewChild(ProgressTimelineComponent, { static: false }) progressTimelineComponent: ProgressTimelineComponent;
  @ViewChild(ProgressTimelineFoodComponent, { static: false }) progressTimelineFoodComponent: ProgressTimelineFoodComponent;

  updateGeneralStopPointUrl = 'api/v1/stop-points';
  updateStopPointUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points/STOP_POINT_ID';
  loadStopsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/project-problem-stop-point-loaded';

  modalOpen = false;

  stopPointData;
  projectProblemId; // is set in project view component
  currentStopPointProjectProblemId = null;
  projectProblemDepartureDatetime;
  warehouseId = null;
  stopPointId;
  serviceTypeTitle;
  stopPointName;
  stopPointServiceType;
  estimatedArrivalTime = '';
  completeAndNotArrived = false;
  inWarehouse = false;
  foodStatusTimes = [];
  foodStatusTimelineCompletedParts = [];
  timelineStatusTimes = [];
  timelineCompletedParts = [];

  completedStatusDeliveryLabel;
  completedStatusPickupLabel;
  pickedUpLabel;
  acceptedLabel;
  fulfillmentStatus;
  fulfillmentStatusConstant;
  fulfillmentTime;
  fulfillmentTimeDifference;
  fulfillmentTimeDifferenceClass;
  activeCanceledReason;
  timeWindowUnformatted = [];
  stopPointAddress;
  foodDepotAddress;
  foodDepotName;
  stopPointContactName;
  stopPointPhone;
  recipientEmail;
  pickupStopPointPhone;
  driverName;
  noDriverName;
  stopPointNotes;
  deliveryLoad;
  pickupLoad;
  duration;
  durationWithOffset = '';
  agreedShippingDate;
  listen = [];
  fulfillmentEvents = [];
  fulfillmentEventsData = [];
  fulfillmentEventsInitial = [];
  pickupCustomerDataId;
  deliveryCustomerDataId;
  customerIdentificationNumber;
  pickupCustomerIdentificationNumber;
  entityStatus;
  projectProblemEntityStatus;
  cancelledLabel;
  cancelledNotes;
  agreedShippingDateLabel;
  agreedShippingTimeWindowLabel;
  cancelledTime;
  returnToCollaboratorLabel;
  returnToCollaboratorConfirmLabel;
  returnNameLabel;
  barcode = '';
  alternativeBarcode = '';
  serialNumber;
  partnerName = '';
  collaboratorName = '';
  consignorName = '';
  consignorType;
  consignorHyperlink = '';
  collaboratorHyperlink = '';
  recipientHyperlink = '';
  depotName = '';
  payAmount = '';
  podPaymentMethod;
  podPaymentMethodLabel: string;
  voucherPaymentMethod;
  voucherPaymentMethodLabel: string;
  signatureName = '';
  signatureHash = '';
  signatureId = null;
  serviceType;
  voucherCharge = null;
  barcodes = [];
  multipleShipments = [];
  isBarcodesListOpen = false;
  isMultipleShipmentsListOpen = false;

  leftLabel;
  priorityOn = false;
  portalOn = false;

  cancelFromAdminMsg;
  selectedFulfillmentStatus;

  serviceTypeTitles = {};

  hasVoucher = false;
  hasImageVoucher = false;
  trackingNumber = '';
  systemicCourierName = '';
  systemicHasError;
  stopPointPin;
  stopPointPhotos = [];

  isSameDayOrNextDay = false;
  pickupStopPointContactName = '';

  inProgressPickupLabel;
  inProgressDeliveryLabel;
  inProgressSoonPickupLabel;
  inProgressSoonDeliveryLabel;
  arrivedLabel;
  statusSmartPointAbsentLabel;

  byCardLabel;
  byCashLabel;
  byBankLabel;
  byChequeLabel;
  byChequeDayLabel;
  collectionDateLabel;
  inCreditLabel;

  driverRating;
  lastVisited;

  speedexHistoryUrl = `https://www.speedex.gr/isapohi.asp?voucher_code=SPEEDEX_BARCODE&searcggo=Submit`

  estimatedArrivalLabel;
  fulfillmentStatusArray = [];
  stopPointFulfillmentEventLabels = [];
  stopPointFulfillmentStatusLabels = [];
  cancelledEventReasonsLabels = {};
  smartPointLabelFulfillmentLabel;
  noNameLabel = '';
  returnLabel = '';
  noNameConstant = '_NO_NAME';
  returnConstant = '_RETURN';
  noEditText = '';
  noEditInvoiceAlertMessage = '';
  noDeleteInvoiceAlertMessage = '';

  editEnabled = false;

  stopPointCCHandleOptions = [];
  stopPointCCHandle;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private viewProjectProblemService: ViewProjectProblemService,
    private projectProblemDataService: ProjectProblemDataService,
    private dateTimeCalculatorService: DateTimeCalculatorService,
    private addressService: AddressService,
    public router: Router,
    private stopPointService: StopPointService,
    private stopPointUtils: StopPointUtils,
    private milyService: MilyService,
    private modalService: ModalService,
    private modalGridService: ModalGridService,
    private genericService: GenericService,
    private changeDetectorRef: ChangeDetectorRef,
    private fulfillmentUtils: FulfillmentUtils,
    private depotUtils: DepotUtils,
    private imageUtils: ImageUtils,
  ) {
    this.listen.push(this.viewProjectProblemService.openStopModalListen().subscribe((data) => {
      this.loadData(data.stopPointId, data.projectProblemId, data.warehouseId);
      this.checkLoadedStatus(data.stopPointId);
      this.stopPointId = data.stopPointId;
      const modal = document.querySelector('.stop-modal');
      if (!this.modalOpen) {
        this.openModal();
      }
    }
    ));
    this.listen.push(this.viewProjectProblemService.closeStopModalListen().subscribe((data) => {
      this.closeModal();
    }
    ));
    this.listen.push(this.viewProjectProblemService.setModalDataListen().subscribe((data) => {
      if (data.stopPoint.id === this.stopPointId) {
        this.setModalData(data);
      }
    }
    ));

    // close any open lists
    this.listen.push(this.viewProjectProblemService.closeStopModalListsListen().subscribe((data) => {
      this.isBarcodesListOpen = false;
      this.isMultipleShipmentsListOpen = false;
    }
    ));
  }

  changestopPointCCHandle() {
    const myObserver = {
      next: (response) => { },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
      },
    };
    const stopPointData = {
      stopPoint: {
        stopPointId: this.stopPointId,
        stop_point_cc_handle: this.stopPointCCHandle,
      }
    };
    this.http.put(this.updateGeneralStopPointUrl, JSON.stringify(stopPointData)).pipe(take(1)).subscribe(myObserver);
  }

  showSpeedexHistory() {
    const iframe = document.createElement('iframe');
    iframe.src = this.speedexHistoryUrl.replace('SPEEDEX_BARCODE', this.barcode);
    iframe.style.border = 'none';
    iframe.style.height = 'calc(100% - 40px)';
    iframe.style.width = '100%';

    this.speedexHistory.nativeElement.classList.add('open');
    this.speedexHistoryBody.nativeElement.appendChild(iframe);
  }

  hideSpeedexHistory() {
    const iframe = this.speedexHistoryBody.nativeElement.querySelector('iframe');
    if (iframe) this.speedexHistoryBody.nativeElement.removeChild(iframe);
    this.speedexHistory.nativeElement.classList.remove('open');
  }

  setBarcodesListVisibility(isOpen) {
    this.isBarcodesListOpen = isOpen;
  }

  setMultipleShipmentsListVisibility(isOpen) {
    this.isMultipleShipmentsListOpen = isOpen;
  }

  portalEnabled() {
    const myObserver = {
      next: (response) => {
        if (response['items']) { }
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
      },
    };
    const stopPointData = {
      stopPoint: {
        portal_access: this.portalOn ? this.globals.portalAccessConstants['ACCESS_NO_HISTORY'] : this.globals.portalAccessConstants['NO_ACCESS'],
      }
    };
    this.http.put(this.updateGeneralStopPointUrl, JSON.stringify(stopPointData)).pipe(take(1)).subscribe(myObserver);
  }

  cancelChangeStopPointFulfillmentStatus(event) {
    let reason = this.fulfillmentStatusConstant * 100;
    if (this.fulfillmentStatusConstant === this.globals.stopPointFulfillmentStatusConstants['CANCELED']) {
      reason = this.activeCanceledReason;
    }
    this.selectedFulfillmentStatus = {
      label: this.fulfillmentStatus,
      reason: reason
    };
  }

  changeStopPointFulfillmentStatus() {
    let projectProblemId = null;
    if (this.stopPointData.stopPoint.project_problem_id) {
      projectProblemId = this.stopPointData.stopPoint.project_problem_id;
    }
    let updateStopPointUrl = this.updateGeneralStopPointUrl.replace('STOP_POINT_ID', this.stopPointId);
    if (projectProblemId) {
      updateStopPointUrl = this.updateStopPointUrl.replace('PROJECT_PROBLEM_ID', projectProblemId);
      updateStopPointUrl = updateStopPointUrl.replace('STOP_POINT_ID', this.stopPointId);
    }

    let stopPointData = {};
    // handed extra warehouse object
    // completed handed
    if (this.selectedFulfillmentStatus.reason == this.globals.stopPointFulfillmentEventConstants[7].COMPLETED_HANDED) {
      stopPointData = {
        stopPoint: {
          warehouse_handed: {
            warehouse_handed_datetime: moment().utc().unix()
          },
          fulfillment_event: {
            reason: this.globals.stopPointFulfillmentEventConstants[7].COMPLETED_HANDED
          }
        }
      }
    }
    // canceled handed
    else if (this.selectedFulfillmentStatus.reason == this.globals.stopPointFulfillmentEventConstants[8].CANCELED_HANDED) {
      stopPointData = {
        stopPoint: {
          warehouse_handed: {
            warehouse_handed_datetime: moment().utc().unix()
          },
          fulfillment_event: {
            reason: this.globals.stopPointFulfillmentEventConstants[8].CANCELED_HANDED
          }
        }
      }
    }
    // all other fulfillments
    else {
      stopPointData = {
        stopPoint: {
          fulfillment_event: {
            reason: this.selectedFulfillmentStatus.reason
          }
        }
      }
    }

    const myObserver = {
      next: (response) => {
        if (response['items']) {
          response['items'].forEach(stopPointResponseData => {
            this.projectProblemDataService.updateSingleStopPoint(stopPointResponseData);
            if (stopPointResponseData.stopPoint.id === this.stopPointId) {
              this.setModalData(stopPointResponseData);
              this.modalService.updateStopPointsGrid();
            }
          });
          this.viewProjectProblemService.updateProjectProblemStatus();
          this.viewProjectProblemService.updateWarehouseGrid();
          this.modalService.updateStopPointsGrid();
          this.modalGridService.updateCustomerGrid();
        }
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.viewProjectProblemService.updateSmartPoints();
      },
    };

    // normal fulfillment change
    if (!this.selectedFulfillmentStatus.returnToCollaborator) {
      const goesToSmartPoint = this.selectedFulfillmentStatus.reason == this.globals.smartPointFulfillmentConstant;
      const goesToPudoPoint = this.selectedFulfillmentStatus.reason == this.globals.pudoFulfillmentConstant;
      // show description modal on all cancel cases except handed canceled
      if (this.fulfillmentUtils.cancelReasons.includes(this.selectedFulfillmentStatus.reason) || goesToSmartPoint || goesToPudoPoint) {
        // find recipient name
        let recipientName = this.stopPointContactName;
        if (this.stopPointUtils.isDelivery(this.stopPointServiceType)) {
          recipientName = this.stopPointContactName;
        } else if (this.stopPointUtils.isDelivery(this.stopPointData.relatedStopPoint?.service_type)) {
          recipientName = this.stopPointData.relatedStopPoint.contact_name;
        }

        const stopPointCoords = { lat: this.stopPointData.stopPoint.address.lat, lon: this.stopPointData.stopPoint.address.lon };
        const warehouseId = this.stopPointData.stopPoint.warehouse_id;
        this.cancelStopPointModalComponent.openModal(this.stopPointId, projectProblemId, this.selectedFulfillmentStatus, this.stopPointServiceType, stopPointCoords, recipientName, warehouseId);
      } else {
        this.http.put(updateStopPointUrl, JSON.stringify(stopPointData)).pipe(take(1)).subscribe(myObserver);
      }
    }
    // return to collaborator
    else {
      let confirmReturnMessage = confirm(this.returnToCollaboratorConfirmLabel);
      if (confirmReturnMessage) {
        let editedStopPointData = {
          stopPoint: {
            contact_name: this.stopPointData.stopPoint.contact_name + ' - ' + this.returnConstant,
            address: this.stopPointData.stopPoint.collaborator.collaborator_depot.address,
            fulfillment_event: {}
          }
        };

        // cancel for another reason if fulfillment is not a canceled one & update stop point address & name
        if (String(this.stopPointData.stopPoint.fulfillment_events[0].reason).charAt(0) != String(this.globals.stopPointFulfillmentStatusConstants['CANCELED'])) {
          editedStopPointData.stopPoint['fulfillment_event']['description'] = this.returnToCollaboratorLabel;
          editedStopPointData.stopPoint['fulfillment_event']['reason'] = this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['CUSTOM'];
          editedStopPointData.stopPoint['fulfillment_status'] = this.globals.stopPointFulfillmentStatusConstants['CANCELED'];

          this.stopPointService.saveStopPoint(editedStopPointData, this.stopPointId, this.stopPointData.stopPoint.project_problem_id, false).pipe(take(1)).subscribe(response => {
            if (response['items']) {
              response['items'].forEach(stopPointResponseData => {
                this.projectProblemDataService.updateSingleStopPoint(stopPointResponseData);
                if (stopPointResponseData.stopPoint.id === this.stopPointId) {
                  this.setModalData(stopPointResponseData);
                }
              });
              this.viewProjectProblemService.updateProjectProblemStatus();
              this.viewProjectProblemService.updateWarehouseGrid();
            }
            this.modalService.updateStopPointsGrid();
          });
        }
        // if fulfillment is canceled, then just update stop point address & name
        else {
          this.stopPointService.saveStopPoint(editedStopPointData, this.stopPointId, this.stopPointData.stopPoint.project_problem_id, false).pipe(take(1)).subscribe(response => {
            if (response['items']) {
              response['items'].forEach(stopPointResponseData => {
                this.projectProblemDataService.updateSingleStopPoint(stopPointResponseData);
                if (stopPointResponseData.stopPoint.id === this.stopPointId) {
                  this.setModalData(stopPointResponseData);
                }
              });
              this.viewProjectProblemService.updateProjectProblemStatus();
              this.viewProjectProblemService.updateWarehouseGrid();
            }
            this.modalService.updateStopPointsGrid();
          });
        }
      }
    }
  }

  changeLoadedStatus() {
    if (this.projectProblemId) {
      const checkbox = document.getElementById('stop-point-modal-loaded-checkbox') as HTMLInputElement;
      if (checkbox.checked) {
        this.viewProjectProblemService.loadStopPoints([this.stopPointId]);
      } else {
        const loadStopsUrl = this.loadStopsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
        const myObserver = {
          next: (response) => {
            if (response) {
              this.projectProblemDataService.loadedStops = response['items'];
            }
          },
          error: (error) => { },
          complete: () => { },
        };

        this.http.delete(loadStopsUrl + '?stopPointIds=' + this.stopPointId).pipe(take(1)).subscribe(myObserver);
      }
    }
  }

  checkLoadedStatus(stopPointId) {
    setTimeout(() => {
      const loadStopsUrl = this.loadStopsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId) + '?stopPointIds=' + stopPointId;

      if (this.projectProblemEntityStatus == this.globals.projectProblemEntityStatusConstants['DISPATCHED'] || this.projectProblemEntityStatus == this.globals.projectProblemEntityStatusConstants['RE_DISPATCH'])
        this.http.get(loadStopsUrl).subscribe(res => {
          const checkbox = document.getElementById('stop-point-modal-loaded-checkbox') as HTMLInputElement;
          if (res['items']) {
            if (res['items'][stopPointId]) {
              checkbox.checked = true;
            } else {
              checkbox.checked = false;
            }
          } else {
            checkbox.checked = false;
          }
        });
    }, 200)
  }

  printImageVoucher() {
    if (this.stopPointData['stopPoint']['voucher']) {
      this.stopPointUtils.printSingleImageVoucher(this.stopPointData['stopPoint']['voucher']['id']);
    } else if (this.stopPointData['relatedStopPoint']['voucher']) {
      this.stopPointUtils.printSingleImageVoucher(this.stopPointData['relatedStopPoint']['voucher']['id']);
    }
  }

  navigateToTrackingService() {
    let courierCode = '';
    switch (this.systemicCourierName) {
      case 'ACS':
        courierCode = 'acscourier';
        break;
      case 'UPS':
        courierCode = 'ups';
        break;
    }

    window.open(`https://aftership.com/track/${courierCode}/${this.trackingNumber}`, '_blank');
  }

  loadData(stopPointId, projectProblemId, warehouseId = null) {
    this.warehouseId = null;
    this.currentStopPointProjectProblemId = projectProblemId;

    if (
      (this.globals.isInRoute('partners') && this.globals.collaboratorModeEnabled) || (this.globals.collaboratorModeEnabled && !this.globals.foodModeEnabled && !this.globals.isInRoute('newShipmentsView'))
    ) {
      this.editEnabled = false;
    } else {
      this.editEnabled = true;
    }

    if (this.globals.isInRoute('warehouse')) {
      this.inWarehouse = true;
    } else {
      this.inWarehouse = false;
    }

    if (warehouseId) {
      this.warehouseId = warehouseId;
    }

    this.fulfillmentTimeDifference = '';
    this.stopPointService.getStopPoint(stopPointId, projectProblemId).pipe(take(1)).subscribe(response => {
      this.setModalData(response['item']);
      this.setFulfillmentDropdown(warehouseId ? true : false);
    });

  }

  setModalData(data) {
    this.stopPointData = data;
    const relatedStopPointData = data['relatedStopPoint'];
    const stopPointData = data['stopPoint'];
    this.estimatedArrivalTime = '';
    this.hasImageVoucher = false;
    this.trackingNumber = '';
    this.systemicCourierName = '';

    const deliveryData = this.stopPointUtils.getDeliveryFromTwoStopPoints(data['stopPoint'], data['relatedStopPoint']);
    const pickupData = this.stopPointUtils.getPickupFromTwoStopPoints(data['stopPoint'], data['relatedStopPoint']);

    // depot name
    const warehouseId = deliveryData?.warehouse_id ? deliveryData.warehouse_id : pickupData.warehouse_id;
    const depotId = this.depotUtils.getFirstDepotWithWarehouseId(warehouseId).companyDepot.id;
    this.depotName = this.depotUtils.getDepotName(this.globals.depots[depotId].companyDepot);

    this.hasVoucher = false;
    // edit in voucher form only shipments that have voucher and are double SPs (pickup&delivery)
    if (data['relatedStopPoint']) {
      if (stopPointData.voucher || data['relatedStopPoint']['voucher']) {
        this.hasVoucher = true;
      }
    }

    if (data['relatedStopPoint']) {
      this.isSameDayOrNextDay = true;
      if (data['stopPoint'].service_type == this.globals.stopPointServiceTypeConstants['PICKUP']) {
        this.pickupStopPointContactName = data['stopPoint'].contact_name;
      } else {
        this.pickupStopPointContactName = data['relatedStopPoint'].contact_name;
      }
    } else {
      this.isSameDayOrNextDay = false;
      this.pickupStopPointContactName = '';
    }

    if (stopPointData.pin) {
      this.stopPointPin = stopPointData.pin;
    } else {
      this.stopPointPin = '-';
    }

    if (stopPointData.photos) {
      if (stopPointData.photos.length) {
        this.loadPhotos(stopPointData.photos);
      } else {
        this.stopPointPhotos = [];
      }
    }
    this.stopPointCCHandle = stopPointData.stop_point_cc_handle ?? this.globals.stopPointCCHandle.DEFAULT;

    if (stopPointData.voucher) {
      const systemicData = stopPointData.voucher['company_voucher_X_systemic_courier'];
      if (systemicData) {
        if (systemicData['tracking_number']) {
          this.hasImageVoucher = true;
          this.trackingNumber = systemicData['tracking_number'];
          this.systemicCourierName = systemicData['systemic_courier_name_const'];
        }
        this.systemicHasError = systemicData['has_error'];
      }

      // consignor 1664668
      this.consignorType = stopPointData.voucher.consignor_type;
      if (this.consignorType == this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR']) {
        this.consignorName = stopPointData.collaborator.collaboratorData.collaborator_name;
        this.consignorHyperlink = '/customerCollaborators/collaboratorView/' + stopPointData.collaborator.collaboratorData.id;
        this.collaboratorHyperlink = this.consignorHyperlink;
      }
    } else if (relatedStopPointData && relatedStopPointData.voucher) {
      const systemicData = relatedStopPointData.voucher['company_voucher_X_systemic_courier'];
      if (systemicData) {
        if (systemicData['tracking_number']) {
          this.hasImageVoucher = true;
          this.trackingNumber = systemicData['tracking_number'];
          this.systemicCourierName = systemicData['systemic_courier_name_const'];
        }
        this.systemicHasError = systemicData['has_error'];
      }


      // sender name
      const consignor = this.stopPointUtils.getConsignorCollaborator(deliveryData.voucher.company_voucher_collaborators);
      const sender = this.stopPointUtils.getSenderCollaborator(deliveryData.voucher.company_voucher_collaborators);
      this.collaboratorName = stopPointData.collaborator_name ? stopPointData.collaborator_name : '-';
      if (consignor) {
        if (consignor.collaborator.collaboratorData.collaborator_name) {
          this.collaboratorName = consignor.collaborator.collaboratorData.collaborator_name;
          this.collaboratorHyperlink = '/customerCollaborators/collaboratorView/' + consignor.collaborator.collaboratorData.id;
        }
      } else if (sender) {
        if (sender.collaborator.collaboratorData.collaborator_name) {
          this.collaboratorName = sender.collaborator.collaboratorData.collaborator_name;
          this.collaboratorHyperlink = '/customerCollaborators/collaboratorView/' + sender.collaborator.collaboratorData.id;
        }
      }
    }

    this.stopPointName = stopPointData.contact_name;
    if (this.stopPointName.includes(this.noNameConstant)) {
      this.stopPointName = this.stopPointName.replace(this.noNameConstant, this.noNameLabel);
    }
    if (this.stopPointName.includes(this.returnConstant)) {
      this.stopPointName = this.stopPointName.replace(this.returnConstant, this.returnLabel);
    }

    // don't show print button if print systemic is disabled
    if (this.globals.collaboratorModeEnabled && !this.globals.partnersCasAccessSystemicVoucher) {
      this.hasImageVoucher = false;
    }

    this.stopPointServiceType = stopPointData.service_type;

    const timeWindows = stopPointData.time_windows;
    this.timeWindowUnformatted = [];
    const timeWindowDouble = [];
    let timeWindowRange, timeWindowRangeMinutes;
    let timeWindowStartOffsetFromDeparture, timeWindowStartOffsetFromDepartureMinutes;
    let timeWindowStart, timeWindowEnd, timeWindowStartMinutes, timeWindowEndMinutes;
    let timeWindowStartUnformatted, timeWindowEndUnformatted;
    timeWindows.forEach(timeWindow => {
      timeWindowRange = timeWindow.time_window_range;
      timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
      timeWindowStartOffsetFromDeparture = timeWindow.time_window_start_offset_from_departure;
      timeWindowStartOffsetFromDepartureMinutes = moment.duration(timeWindowStartOffsetFromDeparture).asMinutes();
      // timeWindowStart = moment(this.projectProblemDepartureDatetime).add(timeWindowStartOffsetFromDepartureMinutes, 'minutes').format();
      timeWindowStart = moment(timeWindow.start, 'HH:mm:SS').format();
      timeWindowEnd = moment(timeWindowStart).add(timeWindowRangeMinutes, 'minutes').format();
      timeWindowStartUnformatted = moment(timeWindowStart).format('HH:mm');
      timeWindowEndUnformatted = moment(timeWindowEnd).format('HH:mm');
      this.timeWindowUnformatted.push(timeWindowStartUnformatted);
      this.timeWindowUnformatted.push(timeWindowEndUnformatted);
      timeWindowStartMinutes = moment.duration(timeWindowStartUnformatted).asMinutes();
      timeWindowEndMinutes = moment.duration(timeWindowEndUnformatted).asMinutes();
    });

    const serviceType = stopPointData.service_type;
    this.serviceTypeTitle = this.serviceTypeTitles[serviceType];

    // set different modal information based on service type & related stop point (same day / next day)
    if (this.stopPointData.stopPoint.service_type == this.globals.stopPointServiceTypeConstants['PICKUP'] && this.stopPointData.relatedStopPoint) {
      this.partnerName = this.stopPointData.relatedStopPoint.partner ? this.stopPointData.relatedStopPoint.partner : '-';
      this.barcode = this.stopPointUtils.getBarcodeOrVoucherHash(this.stopPointData.relatedStopPoint);
      this.alternativeBarcode = this.stopPointData.relatedStopPoint.alternative_barcode ?? null;

      this.stopPointPhone = this.stopPointData.relatedStopPoint.telephone ? this.stopPointData.relatedStopPoint.telephone : '';
      this.pickupStopPointPhone = stopPointData.telephone ? stopPointData.telephone : '';
      if (this.stopPointPhone === 'n/a') { this.stopPointPhone = ''; }
      if (this.stopPointPhone === '+30n/a') { this.stopPointPhone = ''; }
      if (this.pickupStopPointPhone === 'n/a') { this.pickupStopPointPhone = ''; }
      if (this.pickupStopPointPhone === '+30n/a') { this.pickupStopPointPhone = ''; }

      this.stopPointNotes = this.stopPointData.relatedStopPoint.note;
      this.signatureName = this.stopPointData.relatedStopPoint.signature?.signature_name ? this.stopPointData.relatedStopPoint.signature.signature_name : '-';
      this.signatureHash = this.stopPointData.relatedStopPoint.signature?.hash ? this.stopPointData.relatedStopPoint.signature.hash : '-';
      this.portalOn = this.stopPointData.relatedStopPoint.portal_access === this.globals.portalAccessConstants['NO_ACCESS'] ? false : true;
      this.stopPointAddress = this.addressService.getAddressLabel(this.stopPointData.relatedStopPoint.address);
      this.collaboratorName = this.stopPointData.relatedStopPoint.collaborator_name ? this.stopPointData.relatedStopPoint.collaborator_name : '-';
      this.recipientEmail = this.stopPointUtils.getRecipientEmail(this.stopPointData.relatedStopPoint);
      this.payAmount = this.stopPointData.relatedStopPoint.pay_amount ? this.stopPointData.relatedStopPoint.pay_amount + '€' : '-';
      this.serialNumber = this.stopPointData.stopPoint.serial_number ?? this.stopPointData.relatedStopPoint.serial_number;

      this.multipleShipments = [];
      let currentShipment = {};
      if (this.stopPointData.group_shipments) {
        this.stopPointData.group_shipments.forEach(shipment => {
          if (shipment.stop_point_id !== this.stopPointId) {
            this.multipleShipments.push(shipment);
          } else {
            currentShipment = shipment;
          }
        });
      }
      if (this.multipleShipments.length && currentShipment['stop_point_id']) {
        // add self at start of array
        this.multipleShipments.unshift(currentShipment);
      }
      this.multipleShipments = [...this.multipleShipments];

      // payment method for pod (executed_pay_on_delivery_payment_method has priority)
      if (this.stopPointData.relatedStopPoint.executed_pay_on_delivery_payment_method !== this.globals.paymentOptionsConstants['NO_PAYMENT']) {
        this.podPaymentMethod = this.stopPointData.relatedStopPoint.executed_pay_on_delivery_payment_method;
      } else {
        this.podPaymentMethod = this.stopPointData.relatedStopPoint.requested_pay_on_delivery_payment_method;
      }

      // get payment method label only if POD pay amount is > 0
      if (Number(this.stopPointData.relatedStopPoint.pay_amount) > 0) {
        this.podPaymentMethodLabel = this.getPaymentMethodLabel(this.podPaymentMethod);
      }

      // if payment method is cheque, append the collection date
      if (this.podPaymentMethod == this.globals.paymentOptionsConstants['CHEQUE']) {
        this.podPaymentMethodLabel += `, ${this.collectionDateLabel.toLowerCase()}: ${this.stopPointData.relatedStopPoint.cheque_collection_date_pay_on_delivery}`;
      }

      this.stopPointContactName = this.stopPointData.relatedStopPoint.contact_name;
      if (this.stopPointContactName === this.noNameConstant) {
        this.stopPointContactName = this.noNameLabel;
      }
      if (this.stopPointContactName.includes(this.noNameConstant)) {
        this.stopPointContactName = this.stopPointContactName.replace(this.noNameConstant, this.noNameLabel);
      }
      if (this.stopPointContactName.includes(this.returnConstant)) {
        this.stopPointContactName = this.stopPointContactName.replace(this.returnConstant, this.returnLabel);
      }

      // rating
      this.driverRating = null;
      this.lastVisited = null;
      if (stopPointData.relatedStopPoint) {
        if (stopPointData.relatedStopPoint.driver_rating) {
          this.driverRating = stopPointData.relatedStopPoint.driver_rating != null ? stopPointData.relatedStopPoint.driver_rating / 2 : null;
        }
        if (stopPointData.relatedStopPoint.visited_datetime) {
          this.lastVisited = moment(stopPointData.relatedStopPoint.visited_datetime).format('HH:mm') ?? null;
        }
      }
      else {
        if (stopPointData.driver_rating) {
          this.driverRating = stopPointData.driver_rating != null ? stopPointData.driver_rating / 2 : null;
        }
        if (stopPointData.visited_datetime) {
          this.lastVisited = moment(stopPointData.visited_datetime).format('HH:mm') ?? null;
        }
      }

      if (data['customer']) {
        this.pickupCustomerDataId = data['customer'].id;
        this.deliveryCustomerDataId = null;
        this.customerIdentificationNumber = '';
        this.pickupCustomerIdentificationNumber = data['customer'].customer_identification_number;
      } else {
        this.deliveryCustomerDataId = null;
        this.pickupCustomerDataId = null;
        this.customerIdentificationNumber = '';
        this.pickupCustomerIdentificationNumber = '';
      }
    }
    // normal load
    else {
      this.partnerName = stopPointData.partner ? stopPointData.partner : '-';
      this.barcode = this.stopPointUtils.getBarcodeOrVoucherHash(stopPointData);
      this.alternativeBarcode = this.stopPointData.stopPoint.alternative_barcode ?? null;
      this.stopPointPhone = stopPointData.telephone ? stopPointData.telephone : '';
      this.stopPointNotes = stopPointData.note;
      this.signatureName = stopPointData.signature?.signature_name ? stopPointData.signature.signature_name : '-';
      this.signatureHash = stopPointData.signature?.hash ? stopPointData.signature.hash : '-';
      this.payAmount = stopPointData.pay_amount ? stopPointData.pay_amount + '€' : '-';
      this.portalOn = stopPointData.portal_access === this.globals.portalAccessConstants['NO_ACCESS'] ? false : true;
      this.stopPointAddress = this.addressService.getAddressLabel(stopPointData.address);
      this.recipientEmail = this.stopPointUtils.getRecipientEmail(stopPointData);
      this.serialNumber = stopPointData.serial_number;

      this.multipleShipments = [];
      let currentShipment = {};
      if (stopPointData.group_shipments) {
        stopPointData.group_shipments.forEach(shipment => {
          if (shipment.stop_point_id !== this.stopPointId) {
            this.multipleShipments.push(shipment);
          } else {
            currentShipment = shipment;
          }
        });
      }
      if (this.multipleShipments.length) {
        // add self at start of array
        this.multipleShipments.unshift(currentShipment);
      }
      this.multipleShipments = [...this.multipleShipments];

      // payment method for pod (executed_pay_on_delivery_payment_method has priority)
      if (stopPointData.executed_pay_on_delivery_payment_method !== this.globals.paymentOptionsConstants['NO_PAYMENT']) {
        this.podPaymentMethod = stopPointData.executed_pay_on_delivery_payment_method;
      } else {
        this.podPaymentMethod = stopPointData.requested_pay_on_delivery_payment_method;
      }

      // get payment method label only if POD pay amount is > 0
      if (Number(stopPointData.pay_amount) > 0) {
        this.podPaymentMethodLabel = this.getPaymentMethodLabel(this.podPaymentMethod);
      }

      // if payment method is cheque, append the collection date
      if (this.podPaymentMethod == this.globals.paymentOptionsConstants['CHEQUE']) {
        this.podPaymentMethodLabel += `, ${this.collectionDateLabel.toLowerCase()}: ${stopPointData.cheque_collection_date_pay_on_delivery}`;
      }

      // [food mode]: get food depot address
      if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled && relatedStopPointData) {
        this.foodDepotAddress = this.addressService.getAddressLabel(relatedStopPointData['address']);
        this.foodDepotName = relatedStopPointData['contact_name'];
      }

      const consignor = this.stopPointUtils.getConsignorCollaborator(stopPointData?.voucher?.company_voucher_collaborators);
      const sender = this.stopPointUtils.getSenderCollaborator(stopPointData?.voucher?.company_voucher_collaborators);

      // sender name
      this.collaboratorName = stopPointData.collaborator_name ? stopPointData.collaborator_name : '-';
      if (consignor) {
        if (consignor.collaborator.collaboratorData.collaborator_name) {
          this.collaboratorName = consignor.collaborator.collaboratorData.collaborator_name;
          this.collaboratorHyperlink = '/customerCollaborators/collaboratorView/' + consignor.collaborator.collaboratorData.id;
        }
      } else if (sender) {
        if (sender.collaborator.collaboratorData.collaborator_name) {
          this.collaboratorName = sender.collaborator.collaboratorData.collaborator_name;
          this.collaboratorHyperlink = '/customerCollaborators/collaboratorView/' + sender.collaborator.collaboratorData.id;
        }
      }

      this.stopPointContactName = stopPointData.contact_name;
      if (this.stopPointContactName === this.noNameConstant) {
        this.stopPointContactName = this.noNameLabel;
      }

      // rating
      this.driverRating = null;
      this.lastVisited = null;
      if (stopPointData.relatedStopPoint) {
        if (stopPointData.relatedStopPoint.driver_rating) {
          this.driverRating = stopPointData.relatedStopPoint.driver_rating != null ? stopPointData.relatedStopPoint.driver_rating / 2 : null;
        }
        if (stopPointData.relatedStopPoint.visited_datetime) {
          this.lastVisited = moment(stopPointData.relatedStopPoint.visited_datetime).format('HH:mm') ?? null;
        }
      }
      else {
        if (stopPointData.driver_rating) {
          this.driverRating = stopPointData.driver_rating != null ? stopPointData.driver_rating / 2 : null;
        }
        if (stopPointData.visited_datetime) {
          this.lastVisited = moment(stopPointData.visited_datetime).format('HH:mm') ?? null;
        }
      }

      if (data['customer']) {
        this.pickupCustomerDataId = null;
        this.deliveryCustomerDataId = data['customer'].id;
        this.customerIdentificationNumber = data['customer'].customer_identification_number;
        this.pickupCustomerIdentificationNumber = '';
      } else {
        this.deliveryCustomerDataId = null;
        this.pickupCustomerDataId = null;
        this.customerIdentificationNumber = '';
        this.pickupCustomerIdentificationNumber = '';
      }
    }

    // if stop point contains mass_and_dimensions, then get barcodes from it
    // since the pickup might also have a delivery that has the mass_and_dimensions, fall back to that
    // this way we handle pickups with no delivery, too
    let barcodesArray = [];
    this.barcodes = [];
    if (stopPointData.mass_and_dimensions?.length) {
      stopPointData.mass_and_dimensions.forEach(item => {
        if (item.parcels_info) {
          item.parcels_info.forEach(parcel => {
            if (parcel.barcode) barcodesArray.push(parcel.barcode);
          });
        }
      });
    } else if (relatedStopPointData.mass_and_dimensions?.length) {
      relatedStopPointData.mass_and_dimensions.forEach(item => {
        if (item.parcels_info) {
          item.parcels_info.forEach(parcel => {
            if (parcel.barcode) barcodesArray.push(parcel.barcode);
          });
        }
      });
    }
    if (barcodesArray.length > 1) {
      this.barcodes = barcodesArray;
    }

    const loads = this.stopPointUtils.getPickupAndDeliveryLoad({
      stopPoint: stopPointData,
      relatedStopPoint: relatedStopPointData
    });
    this.deliveryLoad = loads.deliveryLoad;
    this.pickupLoad = loads.pickupLoad;

    const durationMinutes = moment.duration(stopPointData.duration, 'minutes').asMinutes();
    const durationMinutesFloor = Math.floor(durationMinutes);
    const durationSeconds = Math.round(moment.duration((durationMinutes - durationMinutesFloor), 'minutes').asSeconds());
    this.duration = durationMinutesFloor + 'm ' + durationSeconds + 's';
    this.agreedShippingDate = '';
    let agreedShipping = {};
    if (stopPointData.agreed_shipping) {
      if (stopPointData.agreed_shipping[0]) {
        agreedShipping = stopPointData.agreed_shipping[0];
        if (stopPointData.agreed_shipping[0]['date']) {
          this.agreedShippingDate = moment(stopPointData.agreed_shipping[0]['date'], 'YYYY-MM-DD').format('DD-MM-YYYY');
        }
      }
    }

    this.voucherCharge = null;
    if (stopPointData.pay_amount_courier_fare) {
      this.voucherCharge = Number(stopPointData.pay_amount_courier_fare);
    } else if (relatedStopPointData?.pay_amount_courier_fare) {
      this.voucherCharge = Number(relatedStopPointData.pay_amount_courier_fare);
    }

    const reasonsToLabels = {
      700: 2,
      600: 1,
      500: 0
    };

    if (this.navigationArrow) {
      // remove fulfillment status classes from the navigation arrow
      this.navigationArrow.nativeElement.classList.remove('in-progress');
      this.navigationArrow.nativeElement.classList.remove('complete');
    }

    this.fulfillmentStatusConstant = stopPointData.fulfillment_status;
    let routeSettingId = null;
    if (stopPointData.solution) {
      routeSettingId = stopPointData.solution.routeSettingId;
      if (this.projectProblemDataService.routeSettingsById[routeSettingId]) {
        const vehicleId = this.projectProblemDataService.routeSettingsById[routeSettingId]['vehicle']['id'];
        const vehicleType = this.projectProblemDataService.routeSettingsById[routeSettingId]['vehicle']['vehicle_type'];
        // if durationOffsets is object and not array
        if (
          typeof this.globals.durationOffsets === 'object' &&
          !Array.isArray(this.globals.durationOffsets) &&
          this.globals.durationOffsets !== null
        ) {
          if (this.globals.durationOffsets[vehicleType]) {
            const durationWithOffsetFormatted = moment.duration(stopPointData.duration).add(moment.duration(this.globals.durationOffsets[vehicleType])).toISOString();
            const durationWithOffsetMinutes = moment.duration(durationWithOffsetFormatted, 'minutes').asMinutes();
            const durationWithOffsetMinutesFloor = Math.floor(durationWithOffsetMinutes);
            const durationWithOffsetSeconds = Math.round(moment.duration((durationWithOffsetMinutes - durationWithOffsetMinutesFloor), 'minutes').asSeconds());
            this.durationWithOffset = durationWithOffsetMinutesFloor + 'm ' + durationWithOffsetSeconds + 's';
          }
        }
      }
    }

    this.cancelledNotes = '';
    this.cancelledLabel = '';
    let arrivedTimeMoment = null;
    this.fulfillmentEventsData = [];
    this.resetFulfillmentEvents();

    this.completeAndNotArrived = false;
    this.activeCanceledReason = null;
    let hasArrivedEvent = false, hasCompleteEvent = false;

    this.progressTimelineComponent.timelineCalculate(stopPointData, this.stopPointData['projectProblem']);
    this.progressTimelineFoodComponent.timelineCalculate(this.stopPointData);
    this.setStatusForCollaboratorFood();

    this.fulfillmentTimeDifferenceClass = '';
    this.fulfillmentStatus = this.stopPointFulfillmentStatusLabels[stopPointData.fulfillment_status];
    let label = this.stopPointFulfillmentStatusLabels[stopPointData.fulfillment_status];

    // in progress label
    if (stopPointData.fulfillment_events[0].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']]['AT_TIME']) {
      if (this.stopPointServiceType == this.globals.stopPointServiceTypeConstants['PICKUP']) {
        label = this.inProgressPickupLabel;
      } else {
        label = this.inProgressDeliveryLabel;
      }
    }
    // in progress (soon) label
    else if (stopPointData.fulfillment_events[0].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']]['PENDING']) {
      if (this.stopPointServiceType == this.globals.stopPointServiceTypeConstants['PICKUP']) {
        label = this.inProgressSoonPickupLabel;
      } else {
        label = this.inProgressSoonDeliveryLabel;
      }
    }
    // completed label
    else if (stopPointData.fulfillment_events[0].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']]['AT_TIME']) {
      if (this.stopPointServiceType == this.globals.stopPointServiceTypeConstants['PICKUP']) {
        label = this.completedStatusPickupLabel;
      } else {
        label = this.completedStatusDeliveryLabel;
      }
    }
    else if (stopPointData.fulfillment_events[0].reason == this.globals.smartPointFulfillmentReasonsStopPoint['FULFILLMENT_REASON_INSERT_TO_PUDO_POINT_RECEIVER_ABSENT']) {
      label = this.statusSmartPointAbsentLabel;
    }

    let reason = stopPointData.fulfillment_events[0].reason;
    if (String(stopPointData.fulfillment_events[0].reason).charAt(0) === String(this.globals.stopPointFulfillmentStatusConstants['CANCELED']) || String(stopPointData.fulfillment_events[0].reason).substring(0, 2) == '18') {
      label = this.cancelledEventReasonsLabels[reason];
      reason = reason;
    }

    // for non-handed events
    if (stopPointData.fulfillment_events[0].reason != this.globals.stopPointFulfillmentEventConstants[7]['COMPLETED_HANDED']
      && stopPointData.fulfillment_events[0].reason != this.globals.stopPointFulfillmentEventConstants[8]['CANCELED_HANDED']) {
      this.selectedFulfillmentStatus = {
        label: label,
        reason: reason
      };
    }
    // for handed events
    else {
      reason = stopPointData.fulfillment_events[0].reason;
      label = this.stopPointFulfillmentStatusLabels[reason];

      this.selectedFulfillmentStatus = {
        label: label,
        reason: reason
      };
    }

    if (stopPointData.solution) {
      this.driverName = stopPointData.solution.driver.userProfile.name;
      const fulfillmentTimeMoment = moment(stopPointData.solution.latest_estimated_arrival_datetime);
      this.fulfillmentTime = fulfillmentTimeMoment.format('HH:mm');
      if (arrivedTimeMoment) {
        this.fulfillmentTimeDifference = Math.round(moment.duration(arrivedTimeMoment.diff(fulfillmentTimeMoment)).asMinutes());
        if (this.fulfillmentTimeDifference > 0) {
          this.fulfillmentTimeDifference = '+' + this.fulfillmentTimeDifference + '`';
          this.fulfillmentTimeDifferenceClass = 'red-letters';
        } else {
          this.fulfillmentTimeDifference = this.fulfillmentTimeDifference + '`';
          this.fulfillmentTimeDifferenceClass = 'green-letters';
        }
      }
    } else {
      this.fulfillmentTime = '';
      this.driverName = this.noDriverName;
    }

    this.entityStatus = stopPointData.entity_status;
    if (stopPointData.entity_status === this.globals.stopPointEntityStatusConstants['ACTIVE']) {
      // checkbox.checked = true;
    } else {
      // checkbox.checked = false;
    }

    if (stopPointData.priority === this.globals.stopPointPriorityConstants['HIGH']) {
      this.priorityOn = true;
    } else {
      this.priorityOn = false;
    }

    const loadedCheckbox = document.getElementById('stop-point-modal-loaded-checkbox') as HTMLInputElement;
    if (loadedCheckbox) {
      if (this.projectProblemDataService.loadedStops[this.stopPointId]) {
        loadedCheckbox.checked = true;
      } else {
        loadedCheckbox.checked = false;
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  getPaymentMethodLabel(paymentMethod) {
    let label = '';

    // method label
    switch (paymentMethod) {
      case this.globals.paymentOptionsConstants['CREDIT_CARD']:
        label = this.byCardLabel.toLowerCase();
        break;
      case this.globals.paymentOptionsConstants['PAY_ON_DELIVERY']:
        label = this.byCashLabel.toLowerCase();
        break;
      case this.globals.paymentOptionsConstants['BANK_DEPOSIT']:
        label = this.byBankLabel.toLowerCase();
        break;
      case this.globals.paymentOptionsConstants['CHEQUE']:
        label = this.byChequeLabel.toLowerCase();
        break;
      case this.globals.paymentOptionsConstants['DAY_CHEQUE']:
        label = this.byChequeDayLabel.toLowerCase();
        break;
      case this.globals.paymentOptionsConstants['NO_PAYMENT']:
        label = this.inCreditLabel.toLowerCase();
        break;
    }

    return label;
  }

  // override status labels (in dropdown) & replace w/ food-appropriate labels
  setStatusForCollaboratorFood() {
    if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled && this.stopPointServiceType != this.globals.stopPointServiceTypeConstants['PICKUP']) {
      if (this.stopPointData['stopPoint']) {
        // received pickup (completed pickup)
        if (this.stopPointData['relatedStopPoint'].fulfillment_events[0].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']].AT_TIME) {
          // override the 'unscheduled' label with completed pickup label, if fulfillment status of pickup is completed (for dropdown selection label)
          this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['UN_SCHEDULED']] = this.completedStatusPickupLabel;
        }
        // arrived pickup
        else if (this.stopPointData['relatedStopPoint'].fulfillment_events[0].reason == this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']].AT_TIME) {
          // override the 'unscheduled' label with arrived label, if fulfillment status of pickup is arrived (for dropdown selection label)
          this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['UN_SCHEDULED']] = this.arrivedLabel;
        }
        // not received yet
        else {
          // override the labels with in-progress label, if fulfillment status of pickup is arrived (for dropdown selection label)
          this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['UN_SCHEDULED']] = this.inProgressPickupLabel;
          this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED']] = this.inProgressPickupLabel;
          this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']] = this.inProgressPickupLabel;
          this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED_PICKUP']] = this.inProgressPickupLabel;
          this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED_DELIVERY']] = this.inProgressPickupLabel;
        }
      }
    }
  }

  deleteStopPoint() {
    const stopPointsToDelete = {
      stop_points: [
        {
          id: this.stopPointData.stopPoint.id,
          warehouse_id: this.stopPointData.stopPoint.warehouse_id,
          project_problem_id: this.stopPointData.stopPoint.project_problem_id
        }
      ]
    };

    const invoiceStatus = this.stopPointData.stopPoint.voucher?.invoice?.status;
    // if in project problem then delete sp only for that project problem, otherwise just delete the sp
    if (invoiceStatus == this.globals.invoiceStatusConstants['PAID'] || invoiceStatus == this.globals.invoiceStatusConstants['APPROVED']) {
      alert(this.noDeleteInvoiceAlertMessage);
    } else if (this.router.url.split('/')[2] == 'projectView') {
      this.viewProjectProblemService.deleteStopPoints([this.stopPointData.stopPoint.id]);
      this.closeModal();
    } else if (this.globals.isInRoute('collaboratorOverview') && this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
      const deleteObj = {
        is_delete_mode: true,
        stopPoints: [
          {
            id: this.stopPointData.stopPoint.id,
            projectProblemId: this.stopPointData.stopPoint.project_problem_id
          }
        ]
      };
      this.http.post('api/v1/partner-new-stop-points', deleteObj).pipe(take(1)).subscribe(response => {
        // this.modalService.updateStopPointsGrid();
        this.closeModal();
      });
    }
    else {
      this.http.post('api/v1/stop-points-delete', stopPointsToDelete).pipe(take(1)).subscribe(response => {
        this.modalService.updateStopPointsGrid();
        this.closeModal();
      });
    }
  }

  showSignature() {
    if (this.signatureName !== '-' && this.signatureHash) {
      this.signatureModalComponent.loadSignature(this.stopPointId, this.signatureHash, this.signatureName);
    }
  }

  showPhoto(base64) {
    this.photoModalComponent.loadPhoto(base64);
  }

  loadPhotos(images) {
    this.stopPointPhotos = [];
    let baseImageUrl = `api/internal/v1/images/stop-point`;
    if (this.globals.collaboratorModeEnabled) baseImageUrl = `api/internal/v1/images/partner-stop-point-images`;
    images.forEach(image => {
      this.imageUtils.fetchImagesViaHashes(baseImageUrl, [image.imageHash]).then(photos => {
        if (photos.length) {
          image['base64'] = photos[0]
          this.stopPointPhotos.push(image);
        }
      });
    });
  }

  deletePhoto(photoId) {
    this.http.delete('api/v1/stop-point/' + this.stopPointId + '/parcel-photo/' + photoId).pipe(take(1)).subscribe();
    const photoIndex = this.stopPointPhotos.findIndex(photo => photo.id == photoId);
    this.stopPointPhotos.splice(photoIndex, 1);
  }

  editStopPoint() {
    const invoiceStatus = this.stopPointData.stopPoint.voucher?.invoice?.status;
    if (!this.hasVoucher && this.globals.collaboratorModeEnabled) {
      alert(this.noEditText);
    } else if (invoiceStatus == this.globals.invoiceStatusConstants['PAID'] || invoiceStatus == this.globals.invoiceStatusConstants['APPROVED']) {
      alert(this.noEditInvoiceAlertMessage);
    } else {
      const projectProblemId = this.inWarehouse ? null : this.currentStopPointProjectProblemId;
      this.viewProjectProblemService.editStopPoint(this.stopPointId, projectProblemId, this.hasVoucher);
      this.closeModal();
    }
  }

  openModal() {
    this.genericService.comm100ZIndexFix();
    this.modalOpen = true;

    setTimeout(() => {
      if (this.historyGridComponent) {
        this.historyGridComponent.closeHistory();
        const modal = document.querySelector('.stop-modal');
        const modalBackground = document.querySelector('.stop-modal-background');
        modal.classList.remove('closed');
        modal.classList.add('open');
        modalBackground.classList.remove('hidden');
      }
    }, 100);
  }

  closeModal() {
    const modal = document.querySelector('.stop-modal');
    const modalBackground = document.querySelector('.stop-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.emptyModal();
    setTimeout(() => {
      this.modalOpen = false;
    }, 1000);
  }

  loadAndOpenCustomerModal(customerDataId) {
    if (customerDataId) {
      this.customerModalComponent.loadAndOpenCustomerModal(customerDataId);
      this.closeModal();
    }
  }

  emptyModal() {
    this.progressTimelineComponent.resetTimeline();

    this.portalOn = false;
    this.hasImageVoucher = false;
    this.trackingNumber = '';
    this.systemicCourierName = '';
    this.stopPointPin = '';
    this.stopPointPhotos = [];
    this.hasVoucher = false;
    this.isSameDayOrNextDay = false;
    this.pickupStopPointContactName = '';
    this.projectProblemDepartureDatetime = '';
    this.pickupCustomerDataId = null;
    this.deliveryCustomerDataId = null;
    this.stopPointName = '';
    this.timeWindowUnformatted = [];
    this.stopPointAddress = null;
    this.stopPointContactName = '';
    this.stopPointPhone = '';
    this.pickupStopPointPhone = '';
    this.recipientEmail = null;
    this.stopPointNotes = '';
    this.deliveryLoad = null;
    this.pickupLoad = null;
    this.duration = '';
    this.durationWithOffset = '';
    this.agreedShippingDate = '';
    this.fulfillmentStatusConstant = null;
    this.estimatedArrivalTime = '';
    this.cancelledNotes = '';
    this.barcode = '';
    this.alternativeBarcode = '';
    this.serialNumber = null;
    this.partnerName = '';
    this.collaboratorName = '';
    this.collaboratorHyperlink = '';
    this.consignorHyperlink = '';
    this.recipientHyperlink = '';
    this.depotName = '';
    this.payAmount = '';
    this.podPaymentMethod = '';
    this.podPaymentMethodLabel = '';
    this.voucherPaymentMethod = '';
    this.voucherPaymentMethodLabel = '';
    this.signatureName = '';
    this.signatureHash = '';
    this.signatureId = null;
    this.fulfillmentEventsData = [];
    this.activeCanceledReason = null;
    this.cancelledNotes = '';
    this.cancelledTime = '';
    this.agreedShippingDateLabel = '';
    this.agreedShippingTimeWindowLabel = '';
    this.fulfillmentTimeDifferenceClass = '';
    this.fulfillmentTime = '';
    this.driverName = this.noDriverName;
    this.voucherCharge = null;
    this.barcodes = [];
    this.multipleShipments = [];
    this.isBarcodesListOpen = false;
    this.isMultipleShipmentsListOpen = false;
    this.entityStatus = null;
    this.priorityOn = false;
    this.customerIdentificationNumber = '';
    this.pickupCustomerIdentificationNumber = '';
    const loadedCheckbox = document.getElementById('stop-point-modal-loaded-checkbox') as HTMLInputElement;
    loadedCheckbox.checked = false;
    // this.tempProjectProblemId = null;
    // this.tempProjectProblemDepartureDatetime = null;
    this.selectedFulfillmentStatus = {};
    this.driverRating = null;
    this.lastVisited = null;
  }

  resetFulfillmentEvents() {
    let currentEvent;
    this.fulfillmentEvents = [];

    // [food mode]: add pickup tracking fulfillment events
    if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
      this.stopPointFulfillmentEventLabels = [];
      this.stopPointFulfillmentEventLabels.push(this.acceptedLabel);
      this.stopPointFulfillmentEventLabels.push(this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']]);
      this.stopPointFulfillmentEventLabels.push(this.pickedUpLabel);
      this.stopPointFulfillmentEventLabels.push(this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']]);
      this.stopPointFulfillmentEventLabels.push(this.completedStatusDeliveryLabel);
    }

    this.stopPointFulfillmentEventLabels.forEach(eventLabel => {
      currentEvent = {
        actualTime: '',
        timeDifference: '',
        label: eventLabel,
        class: ''
      };
      this.fulfillmentEvents.push(currentEvent);
    });
  }

  setFulfillmentDropdown(inWarehouse = false) {
    let fulfillmentStatusArray = [];
    const completedReason = this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']]['AT_TIME'];

    // fulfillments: handed options (only available on pickups)
    let completedLabel;
    if (this.stopPointServiceType == this.globals.stopPointServiceTypeConstants['PICKUP']) {
      completedLabel = this.completedStatusPickupLabel;
      fulfillmentStatusArray = [
        {
          label: completedLabel,
          reason: completedReason
        }
      ];
      this.fulfillmentUtils.pickupNotHandedCancelReasons.forEach(reason => {
        if (this.cancelledEventReasonsLabels[reason]) {
          fulfillmentStatusArray.push({
            label: this.cancelledEventReasonsLabels[reason],
            reason: reason
          });
        }
      });
      if (this.globals.isChangeToDeliverLaterEnabled) {
        fulfillmentStatusArray.push({
          label: this.inProgressSoonPickupLabel,
          reason: this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']]['PENDING']
        });
      }
    }
    // fulfillments: non-pickup
    else {
      completedLabel = this.completedStatusDeliveryLabel;
      fulfillmentStatusArray = [
        {
          label: completedLabel,
          reason: completedReason
        }
      ];
      this.fulfillmentUtils.deliveryNotHandedCancelReasons.forEach(reason => {
        if (this.cancelledEventReasonsLabels[reason]) {
          fulfillmentStatusArray.push({
            label: this.cancelledEventReasonsLabels[reason],
            reason: reason
          });
        }
      });

      const canBeAssignedToSmartPoint = this.stopPointData.stopPoint.can_be_assigned_to_smart_point;
      // smart point option
      if (canBeAssignedToSmartPoint) {
        fulfillmentStatusArray.push({
          label: this.smartPointLabelFulfillmentLabel,
          reason: this.globals.smartPointFulfillmentConstant
        });
        fulfillmentStatusArray = [...fulfillmentStatusArray];
      }
      if (this.globals.isPudoPointsEnabled && this.globals.hasActivePudoPoints && this.stopPointPhone && this.stopPointPhone != 'n/a') {
        fulfillmentStatusArray.push({
          label: this.smartPointLabelFulfillmentLabel,
          reason: this.globals.pudoFulfillmentConstant
        });
        fulfillmentStatusArray = [...fulfillmentStatusArray];
      }
      if (this.globals.isChangeToDeliverLaterEnabled) {
        fulfillmentStatusArray.push({
          label: this.inProgressSoonDeliveryLabel,
          reason: this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']]['PENDING']
        });
      }
    }

    // in-progress status
    if (!inWarehouse) {
      let inProgressLabel;
      if (this.stopPointServiceType == this.globals.stopPointServiceTypeConstants['PICKUP']) {
        inProgressLabel = this.inProgressPickupLabel;
      } else {
        inProgressLabel = this.inProgressDeliveryLabel;
      }

      let inProgressItem = {
        label: inProgressLabel,
        reason: this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']]['AT_TIME']
      };

      fulfillmentStatusArray.splice(1, 0, inProgressItem);
    }

    // return to collaborator option (only in delivery & collaborator must have address)
    if (this.stopPointData.stopPoint.collaborator) {
      if (this.stopPointData.stopPoint.service_type == this.globals.stopPointServiceTypeConstants['DELIVERY'] && this.stopPointData.stopPoint.collaborator.address.value != '') {
        const returnToCollaboratorItem = {
          label: this.returnToCollaboratorLabel,
          reason: this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']]['CUSTOM'],
          returnToCollaborator: true
        };
        fulfillmentStatusArray.splice(1, 0, returnToCollaboratorItem);
      }
    }

    this.fulfillmentStatusArray = fulfillmentStatusArray;
  }

  getTranslations() {
    this.listen.push(this.translate.get('TIME.LEFT').subscribe((res: string) => {
      this.leftLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.CANCELLED').subscribe((res: string) => {
      this.cancelledLabel = res;
    }));
    this.listen.push(this.translate.get('CANCEL.CANCEL_FROM_ADMIN_MSG').subscribe((res: string) => {
      this.cancelFromAdminMsg = res;
    }));

    this.listen.push(this.translate.get('STATUS.NOT_SPECIFIED').subscribe((res: string) => {
      this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['NOT_SPECIFIED']] = res;
    }));
    this.listen.push(this.translate.get('STATUS.UN_SCHEDULED').subscribe((res: string) => {
      this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['UN_SCHEDULED']] = res;
    }));
    this.listen.push(this.translate.get('STATUS.SCHEDULED').subscribe((res: string) => {
      this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED']] = res;
    }));
    this.listen.push(this.translate.get('STATUS.SCHEDULED_PICKUP').subscribe((res: string) => {
      this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED_PICKUP']] = res;
    }));
    this.listen.push(this.translate.get('STATUS.SCHEDULED_DELIVERY').subscribe((res: string) => {
      this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED_DELIVERY']] = res;
    }));
    this.listen.push(this.translate.get('STATUS.LOADING').subscribe((res: string) => {
      this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['LOADING']] = res;
    }));
    this.listen.push(this.translate.get('STATUS.IN_PROGRESS').subscribe((res: string) => {
      this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']] = res;
    }));
    this.listen.push(this.translate.get('STATUS.IN_PROGRESS_PICKUP').subscribe((res: string) => {
      this.inProgressPickupLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.IN_PROGRESS_DELIVERY').subscribe((res: string) => {
      this.inProgressDeliveryLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.IN_PROGRESS_SOON_PICKUP').subscribe((res: string) => {
      this.inProgressSoonPickupLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.IN_PROGRESS_SOON_DELIVERY').subscribe((res: string) => {
      this.inProgressSoonDeliveryLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.ARRIVED').subscribe((res: string) => {
      this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']] = res;
      this.arrivedLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.COMPLETED').subscribe((res: string) => {
      this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']] = res;
    }));
    this.listen.push(this.translate.get('STATUS.COMPLETED').subscribe((res: string) => {
      this.completedStatusDeliveryLabel = res;
    }));
    if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
      this.listen.push(this.translate.get('STATUS.COMPLETED_PICKUP_FOOD').subscribe((res: string) => {
        this.completedStatusPickupLabel = res;
      }));
      this.listen.push(this.translate.get('STATUS.PICKED_UP').subscribe((res: string) => {
        this.pickedUpLabel = res;
      }));
      this.listen.push(this.translate.get('STATUS.ACCEPTED').subscribe((res: string) => {
        this.acceptedLabel = res;
      }));
    } else {
      this.listen.push(this.translate.get('STATUS.COMPLETED_PICKUP').subscribe((res: string) => {
        this.completedStatusPickupLabel = res;
      }));
    }
    this.listen.push(this.translate.get('STATUS.CANCELED').subscribe((res: string) => {
      this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['CANCELED']] = res;
    }));
    this.listen.push(this.translate.get('STATUS.DROPPED').subscribe((res: string) => {
      this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentStatusConstants['DROPPED']] = res;
    }));
    // handed completed
    this.listen.push(this.translate.get('STATUS.HANDED_COMPLETED').subscribe((res: string) => {
      this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentEventConstants[7]['COMPLETED_HANDED']] = res;
    }));

    // handed canceled
    this.listen.push(this.translate.get('STATUS.HANDED_COMPLETED').subscribe((res: string) => {
      this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentEventConstants[7]['COMPLETED_HANDED']] = res;
    }));

    this.listen.push(this.translate.get('STATUS.HANDED_CANCELED').subscribe((res: string) => {
      this.stopPointFulfillmentStatusLabels[this.globals.stopPointFulfillmentEventConstants[8]['CANCELED_HANDED']] = res;
    }));
    this.listen.push(this.translate.get('STATUS.START').subscribe((res: string) => {
      this.stopPointFulfillmentEventLabels[0] = res;
    }));
    this.listen.push(this.translate.get('STATUS.ARRIVED').subscribe((res: string) => {
      this.stopPointFulfillmentEventLabels[1] = res;
    }));
    this.listen.push(this.translate.get('STATUS.ESTIMATED_ARRIVAL').subscribe((res: string) => {
      this.estimatedArrivalLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.COMPLETED').subscribe((res: string) => {
      this.stopPointFulfillmentEventLabels[2] = res;
    }));
    this.listen.push(this.translate.get('CANCEL').subscribe((res: string) => {
      this.fulfillmentUtils.setupReasonLabels(res);
      this.cancelledEventReasonsLabels = this.fulfillmentUtils.cancelledEventReasonsLabels;
    }));
    this.listen.push(this.translate.get('CANCEL.SMART_POINT_ACCEPT_IT').subscribe((res: string) => { this.smartPointLabelFulfillmentLabel = res }));
    this.listen.push(this.translate.get('STATUS.RETURN_TO_COLLABORATOR').subscribe((res: string) => {
      this.returnToCollaboratorLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.RETURN_TO_COLLABORATOR_CONFIRM').subscribe((res: string) => {
      this.returnToCollaboratorConfirmLabel = res;
    }));
    this.listen.push(this.translate.get('STATUS.RETURN').subscribe((res: string) => {
      this.returnNameLabel = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT.DELIVERY_TITLE').subscribe((res: string) => {
      this.serviceTypeTitles[this.globals.stopPointServiceTypeConstants['DELIVERY']] = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT.PICKUP_TITLE').subscribe((res: string) => {
      this.serviceTypeTitles[this.globals.stopPointServiceTypeConstants['PICKUP']] = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT.BOTH_TITLE').subscribe((res: string) => {
      this.serviceTypeTitles[this.globals.stopPointServiceTypeConstants['BOTH']] = res;
    }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.RETURN').subscribe((res: string) => { this.returnLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.NO_DRIVER_NAME').subscribe((res: string) => { this.noDriverName = res; }));
    this.listen.push(this.translate.get('STOP_POINT.NO_EDIT').subscribe((res: string) => { this.noEditText = res; }));
    this.listen.push(this.translate.get('STOP_POINT.NO_EDIT_INVOICE_ALERT').subscribe((res: string) => { this.noEditInvoiceAlertMessage = res; }));
    this.listen.push(this.translate.get('STOP_POINT.NO_DELETE_INVOICE_ALERT').subscribe((res: string) => { this.noDeleteInvoiceAlertMessage = res; }));

    this.listen.push(this.translate.get('STOP_POINT.BY_CARD').subscribe((res: string) => { this.byCardLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.BY_CASH').subscribe((res: string) => { this.byCashLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.BY_BANK').subscribe((res: string) => { this.byBankLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.BY_CHEQUE').subscribe((res: string) => { this.byChequeLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.BY_CHEQUE_DAY').subscribe((res: string) => { this.byChequeDayLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.COLLECTION_DATE').subscribe((res: string) => { this.collectionDateLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.IN_CREDIT').subscribe((res: string) => { this.inCreditLabel = res; }));
    this.listen.push(this.translate.get('CANCEL.SMART_POINT_ABSENT').subscribe((res: string) => { this.statusSmartPointAbsentLabel = res }));
    this.listen.push(this.translate.get('STOP_POINT').subscribe((res: string) => {
      this.stopPointCCHandleOptions = [
        {
          value: this.globals.stopPointCCHandle.DEFAULT,
          label: res['NONE']
        },
        {
          value: this.globals.stopPointCCHandle.URGENT,
          label: res['URGENT']
        },
        {
          value: this.globals.stopPointCCHandle.PAUSED,
          label: res['PAUSE']
        },
      ];
      this.stopPointCCHandle = this.globals.stopPointCCHandle.DEFAULT;
    }));
    // this.setFulfillmentDropdown();
    this.resetFulfillmentEvents();
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
