import { GenericUtils } from '@app/utils/generic-utils';
import { GenericService } from '@app/services/generic.service';
import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PreloadStopPointsService } from '@app/services/preload-stop-points.service';
import { AuthService } from './auth.service';
import { SwUpdate } from '@angular/service-worker';
import moment from 'moment';
import * as momentTZ from 'moment-timezone';
import 'moment/locale/el';
import { deviceIsMobile } from './shared/utils';

declare var Upscope: any;
declare var Comm100API: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnDestroy, OnInit, AfterViewInit {
    listen = [];

    title = 'Lastmily';
    chatUnsubscribeFunctions = [];

    chatZIndex = '899'; // comm100 assigns values like 2147483644 to the element above the chat so this number ensures that chat is always on top
    timeOutMsToUpdateChatButton = 2000;

    isAuthenticated;

    checkForAppUpdateInterval;

    constructor(
        public globalConstants: Globals,
        private swUpdate: SwUpdate,
        public translate: TranslateService,
        public router: Router,
        private titleService: Title,
        public preloadStopPointsService: PreloadStopPointsService,
        public auth: AuthService,
        public genericService: GenericService,
        public genericUtils: GenericUtils,
        public appVCR: ViewContainerRef,
        private _renderer: Renderer2
    ) {
        // Periodically check for web-app updates (every 30min.)
        clearInterval(this.checkForAppUpdateInterval);
        this.checkForAppUpdateInterval = null;
        console.log('CHECKING FOR NEW WEB APP VERSION UPDATES...');
        this.swUpdate.checkForUpdate().then((isUpdateFound) => {
            if (isUpdateFound) {
                console.warn('NEW VERSION FOUND @ ' + String(moment().format('DD/MM/YYYY HH:mm:ss')));

                // Reload after new version hash is changed in globals! (w/ 10s delay)
                setTimeout(() => {
                    location.reload();
                }, 10000);
            }
        });
        this.checkForAppUpdateInterval = setInterval(() => {
            console.log('CHECKING FOR NEW WEB APP VERSION UPDATES...');
            this.swUpdate.checkForUpdate().then((isUpdateFound) => {
                if (isUpdateFound) {
                    console.warn('NEW VERSION FOUND @ ' + String(moment().format('DD/MM/YYYY HH:mm:ss')));

                    // Reload after new version hash is changed in globals! (w/ 10s delay)
                    setTimeout(() => {
                        location.reload();
                    }, 10000);
                }
            });
        }, 1800000); // 1800000ms = 30m

        this.listen.push(this.genericService.listenComm100ZIndexFix().subscribe(() => {
            this.updateChatButtonZIndex();
        }));

        translate.addLangs(['en', 'el', 'de', 'ro']);
        let lang;

        // get stored language (if in Live Tracking OR no language is stored, then use the browser's preferred language)
        const browserLang = navigator.language;
        if (this.globalConstants.isInClientPortal()) {
            lang = getBrowserPreferredLanguage(browserLang);
        } else {
            lang = localStorage.getItem('lang');

            // because lang var was read wrong, in some cases lang was undefined and was written as 'undefined' in the local storage
            if (!lang || lang == 'undefined') {
                lang = getBrowserPreferredLanguage(browserLang);
            }
        }

        translate.use(lang);
        this.globalConstants.currentLang = lang;
        translate.setDefaultLang(lang);
        if (lang) {
            localStorage.setItem('lang', lang);
        }
        this.setMomentLanguage();

        const comm100 = document.querySelector('[id^="comm100-button"] iframe') as HTMLElement;
        this.listen.push(router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (
                    window.location.href.includes('/client/') ||
                    window.location.href.includes('/login') ||
                    window.location.href.includes('/register') ||
                    window.location.href.includes('/demo')
                ) {
                    if (Comm100API.on) {
                        Comm100API.set('livechat.button.isVisible', false);
                    }
                } else {
                    if (Comm100API.on) {
                        Comm100API.set('livechat.button.isVisible', true);
                    }
                }

                if (window.location.href.includes('/projectView/')) {
                    if (Comm100API.on) {
                        this.setListenersForChatInProjectView();
                    }
                } else {
                    this.unsubscribeFromListenersForChatButtonPosition();
                }
            }
            this.updateChatButtonZIndex();
        }));

        function getBrowserPreferredLanguage(browserLanguage) {
            let lang;
            switch (browserLanguage) {
                case 'el':
                case 'el-GR':
                    lang = 'el';
                    break;
                case 'en':
                case 'en-GB':
                case 'en-AU':
                case 'en-IE':
                case 'en-US':
                case 'en-ZA':
                    lang = 'en';
                    break;
                case 'de':
                case 'de-AT':
                case 'de-CH':
                case 'de-DE':
                case 'de-LI':
                    lang = 'de';
                    break;
                case 'ro':
                    lang = 'ro';
                    break;
                default:
                    lang = 'en';
                    break;
            }
            return lang;
        }
    }

    setMomentLanguage() {
        moment.locale(this.globalConstants.currentLang);
        momentTZ.locale(this.globalConstants.currentLang);
    }

    updateChatButtonZIndex() {
        const comm100 = document.querySelector('[id^="comm100-button"] iframe') as HTMLElement;
        const comm100AdaptiveChat = document.getElementById('chat_button_adaptive_container');
        const problematicIFrame = document.querySelector('#comm100-container>div div:nth-child(6) div iframe') as HTMLElement;

        setTimeout(() => {
            if (comm100) {
                comm100.style.zIndex = this.chatZIndex;
            }
            if (comm100AdaptiveChat) {
                comm100AdaptiveChat.style.zIndex = this.chatZIndex;
            }

            // sometimes if the chat is open, the problematicIFrame query catches the chat button
            // if the element we catch has an id, it is likely the chat button and we don't want to hide it
            // so, if we catch the chat button, is is because the chat was open and someone closed it
            // then, we wait 1s for the chat to close, and then we query again to find the problematic iframe
            // if the second iframe we catch has no id, we hide it 
            if (problematicIFrame) {
                if (!problematicIFrame.id) {
                    problematicIFrame.style.zIndex = '-1';
                } else {
                    setTimeout(() => {
                        const problematicIFrame2 = document.querySelector('#comm100-container>div span:nth-child(4) div:nth-child(2) iframe') as HTMLElement;
                        if (!problematicIFrame2.id) {
                            problematicIFrame2.style.zIndex = '-1';
                        }
                    }, 1000);
                }
            }
        }, this.timeOutMsToUpdateChatButton);
    }

    private static loadStyle(styleUrl: string, integrity: string = '') {
        const styleElement = document.createElement('link');
        styleElement.setAttribute('rel', 'stylesheet');
        styleElement.setAttribute('href', styleUrl);
        styleElement.setAttribute('crossOrigin', 'anonymous');
        if (integrity) {
            styleElement.setAttribute('integrity', integrity);
        }
        document.head.appendChild(styleElement);
    }

    private static loadScript(scriptUrl: string) {
        const scriptElement = document.createElement('script');
        scriptElement.setAttribute('src', scriptUrl);
        document.head.appendChild(scriptElement);
    }

    getTitle(state, parent) {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            data.push(parent.snapshot.data.title);
        }

        if (state && parent) {
            data.push(... this.getTitle(state, state.firstChild(parent)));
        }
        return data;
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    movePreChatUp() {
        const preChatElements = document.querySelector('body > div > iframe');
        if (preChatElements) {
            preChatElements.classList.add('high');
        }
    }

    moveChatUp() {
        const chatElements = document.querySelectorAll('body #comm100-container iframe');
        if (chatElements) {
            chatElements.forEach(element => {
                element.classList.add('high');
                this.updateChatButtonZIndex();
            });
        }
    }

    removeCallBackgroundImage() {
        const iframeElement = document.querySelector('body #comm100-container iframe#side_window_container') as HTMLIFrameElement;
        const backgroundElement = iframeElement.contentWindow.document.getElementById('media-chat-window');
        iframeElement.contentWindow.document.getElementById('media-chat-window').style.backgroundImage = 'none';
    }

    setListenersForChatInProjectView() {
        setTimeout(() => {
            this.moveChatUp();
            this.movePreChatUp();
        }, 100);
        this.chatUnsubscribeFunctions.push(Comm100API.on('livechat.prechat.display', () => {
            this.moveChatUp();
            const fields = Comm100API.get('livechat.prechat.fields');
            fields[0]['value'] = this.globalConstants.userName;
            Comm100API.set('livechat.prechat.fields', fields);
            Comm100API.set('livechat.prechat.isSkip', true);
        }));
        this.chatUnsubscribeFunctions.push(Comm100API.on('livechat.prechat.close', () => {
            this.moveChatUp();
            this.movePreChatUp();
        }));
        this.chatUnsubscribeFunctions.push(Comm100API.on('livechat.prechat.minimize', () => {
            this.moveChatUp();
        }));
        this.chatUnsubscribeFunctions.push(Comm100API.on('livechat.chat.display', () => {
            this.moveChatUp();
        }));
        this.chatUnsubscribeFunctions.push(Comm100API.on('livechat.chat.close', () => {
            this.moveChatUp();
        }));
        this.chatUnsubscribeFunctions.push(Comm100API.on('livechat.chat.minimize', () => {
            this.moveChatUp();
        }));
        this.chatUnsubscribeFunctions.push(Comm100API.on('livechat.postChat.close', () => {
            this.moveChatUp();
        }));
        this.chatUnsubscribeFunctions.push(Comm100API.on('livechat.postChat.minimize', () => {
            this.moveChatUp();
        }));
        this.chatUnsubscribeFunctions.push(Comm100API.on('livechat.offlineMessage.close', () => {
            this.moveChatUp();
        }));
        this.chatUnsubscribeFunctions.push(Comm100API.on('livechat.offlineMessage.minimize', () => {
            this.moveChatUp();
        }));
    }

    unsubscribeFromListenersForChatButtonPosition() {
        this.chatUnsubscribeFunctions.forEach(func => {
            func();
        });
    }

    ngOnInit() {
        if (this.auth.isAuthenticated()) {
            this.preloadStopPointsService.loadPossibleProjectProblemsStopPoints();
            // this.isAuthenticated = true;
        }
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.setMomentLanguage();
        }));
    }

    ngAfterViewInit() {
        this._renderer.setStyle(document.body, 'min-width', (deviceIsMobile() ? 'unset' : '1280px'))
        const options = {};
        const elements = document.querySelectorAll('.sidenav');
        Upscope('updateConnection', {
            identities: [this.globalConstants.userName]
        });
        Comm100API.onReady = () => {
            if (window.location.href.includes('/projectView/')) {
                this.setListenersForChatInProjectView();
            }
            Comm100API.on('livechat.chat.audio.request', () => {
                this.removeCallBackgroundImage();
                if (window.location.href.includes('/projectView/')) {
                    this.moveChatUp();
                }
            });
            this.chatUnsubscribeFunctions.push(Comm100API.on('livechat.prechat.display', () => {
                const fields = Comm100API.get('livechat.prechat.fields');
                fields[0]['value'] = this.globalConstants.userName;
                Comm100API.set('livechat.prechat.fields', fields);
                Comm100API.set('livechat.prechat.isSkip', true);
            }));
            const footer = document.createElement("div");
            footer.innerHTML = "Lastmily support team";
            footer.style.padding = '0 10px';
            footer.style.textAlign = 'right';
            footer.style.color = '#00aeba';
            // const footer = '<div>Lastmily support team</div>';
            Comm100API.render('livechat.prechat.footer.poweredby', () => {
                // return custom HTMLElement to replace default component
                return footer;
            });
            Comm100API.render('livechat.chat.footer.poweredby', () => {
                // return custom HTMLElement to replace default component
                return footer;
            });
            Comm100API.render('livechat.postChat.footer.poweredby', () => {
                // return custom HTMLElement to replace default component
                return footer;
            });
            Comm100API.render('livechat.offlineMessage.footer.poweredby', () => {
                // return custom HTMLElement to replace default component
                return footer;
            });
            if (
                window.location.href.includes('/client/') ||
                window.location.href.includes('/c/') ||
                window.location.href.includes('/printVouchers/') ||
                window.location.href.includes('/printExtraVouchers/') ||
                window.location.href.includes('/login') ||
                window.location.href.includes('/register') ||
                window.location.href.includes('/demo')
            ) {
                Comm100API.set('livechat.button.isVisible', false);
            } else {
                Comm100API.set('livechat.button.isVisible', true);
            }
            this.updateChatButtonZIndex();
        };
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
